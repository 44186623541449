import { userLogin, userLoginBySession } from "../api";
import { ContextState, locationGroupSetter, loginMessageSetter, loginSetter, useAPI, useAppState, userSetter } from "../context";
import { useLocationGroup } from './';

interface UseLogin {
	login: (name: string, pass: string) => Promise<boolean>;
	loginBySession: () => Promise<void>;
	logout: () => void;
}

export const useLogin = (): [ContextState["user"], UseLogin]  => {
	const [state, setState] = useAppState();
	const [, { getLocationGroup }] = useLocationGroup();
	const callAPI = useAPI();

	const login = async (name: string, pass: string) => {
		try {
			const result = await callAPI(userLogin(name, pass));

			const user = result.data;
			localStorage.setItem('token', user.token);

			const locationGroup = await getLocationGroup(user?.permission?.locationGroupId);
			if (!locationGroup) {
				localStorage.removeItem('token');
				throw new Error('ログインに失敗しました。ユーザ名かパスワードが異なる可能性があります。');
			}

			setState([userSetter(user), loginSetter(null), locationGroupSetter(locationGroup)]);
			return true;

		} catch (err) {
			if (err instanceof Error && err.message) {
				setState(loginMessageSetter(err.message));
			} else {
				setState(loginMessageSetter('ログインに失敗しました。ユーザ名かパスワードが異なる可能性があります。'));
			}
			console.log(err);
		}
		return false;
	};

	const loginBySession = async () => {
		try {
			const result = await callAPI(userLoginBySession());

			const user = result.data;

			const locationGroup = await getLocationGroup(user?.permission?.locationGroupId);
			if (!locationGroup) {
				localStorage.removeItem('token');
				throw new Error('セッションの有効期限が切れています。');
			}

			setState([userSetter(user), loginSetter(null), locationGroupSetter(locationGroup)]);

		} catch (err) {
			console.log(err);
		}
	};

	const logout = () => {
		localStorage.removeItem('token');
		setState([userSetter(null), loginSetter(null), locationGroupSetter(null)]);
	};

	return [
		state.user,
		{
			login,
			loginBySession,
			logout,
		}
	];
};