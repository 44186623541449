import * as React from 'react';
import { useForm } from 'react-hook-form';
import { MOVING_STATE, MOVING_STATE_LIST } from '../../../const';
import { MovingConditions, useLocationGroup, useMovings } from '../../../hooks';
import { LocationGroup } from '../../../types';
import { PageBlock } from '../../molecules/PageBlock';
import { PageMain } from '../../molecules/PageMain';
import { Form, InputRadio } from '../../organisms/Form';
import { MovingListTable } from './MovingListTable';

const initValues = {
	state: 'moving',
}
type FormValues = typeof initValues;

export const MovingList: React.FC = () => {
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const { getValues } = formMethods;
	const [locationGroup, { getLocationGroups }] = useLocationGroup();

	const [locationGroups, setLocationGroups] = React.useState<LocationGroup[]>([]);
	const [movingConds, setMovingConds] = React.useState<MovingConditions>({
		...initValues,
		destLocationGroupId: locationGroup?.id
	});

	const movings = useMovings(movingConds);

	React.useEffect(() => {
		const load = async () => {
			const lgs = await getLocationGroups();
			setLocationGroups(lgs);
		};

		load();
	}, []);

	const handleClickFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { state } = getValues();
		setMovingConds({
			state,
			destLocationGroupId: locationGroup?.id
		});
	};

	return (
		<PageMain title="移動伝票">
			<PageBlock>
				<section>
					<Form methods={formMethods}>
						<div className="menu-container">
							<div className="menu-row">
								<div className="menu-col-6">
									ステータス<br />
									<div className="state-selector">
										{MOVING_STATE_LIST.map((state) => 
											<InputRadio key={state} name="state" onChange={handleClickFilter} value={state}>{MOVING_STATE[state]}</InputRadio>
										)}
									</div>
								</div>
								<div className="menu-col-6">
									移動先グループ<br />
									<div className="state-selector">
										{locationGroup?.name}
									</div>
								</div>
							</div>
						</div>
					</Form>
				</section>
			</PageBlock>
			<PageBlock title="移動伝票一覧">
				<section>
					<MovingListTable movings={movings} locationGroups={locationGroups} />
				</section>
			</PageBlock>
		</PageMain>
	);
}
export default MovingList;