import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ContextProvider from './ContextProvider';
import When from './When';
import { LayoutLoggedIn } from './organisms/Layout';
import { Dashboard } from './pages/Dashboard';
import { Inventory } from './pages/Inventory';
import { Login } from './pages/Login';
import { MovingEdit } from './pages/MovingEdit';
import { MovingList } from './pages/MovingList';
import { PrintPickingList } from './pages/PrintPickingList';
import { PrintTotalPickingList } from './pages/PrintTotalPickingList';
import { ShippingList } from './pages/ShippingList';

const App: React.FunctionComponent = () => {

	return (
		<ContextProvider>
			<When not loggedin>
				<Routes>
					<Route path="*" element={<Login />} />
				</Routes>
			</When>
			<When loggedin>
				<Routes>
					<Route path="/print/picking" element={<PrintPickingList />} />
					<Route path="/print/totalPicking" element={<PrintTotalPickingList />} />
					<Route path="/" element={<LayoutLoggedIn />}>
						<Route index element={<Dashboard />} />
						<Route path="/inventory" element={<Inventory />} />
						<Route path="/inventory/moving" element={<MovingList />} />
						<Route path="/inventory/moving/:id/store" element={<MovingEdit isStoring />} />
						<Route path="/inventory/list" element={<Inventory />} />
						<Route path="/shipping" element={<ShippingList />} />
					</Route>
				</Routes>
			</When>
		</ContextProvider>
	);
};
export default App;
