/**
 * 非同期処理の実行を排他制御する
 * 
 * コンストラクタに排他制御したい関数を指定し、`trigger`メソッドで実行する。
 * 実行中の場合は、同時実行せずに実行中の処理の`Promise`を返す。
 */
export class ExclusiveExecution<R = void> {
	func: () => Promise<R> | R;
	err?: (err: unknown) => R;
	private promise: Promise<R> | null = null;
	private hasNext = false;
	isClose = false;

	constructor(func: () => Promise<R> | R, err?: (err: unknown) => R) {
		this.func = func;
		this.err = err;
	}

	/**
	 * コンストラクタで設定された関数を実行する。
	 * @param delay 既に実行中の場合、その実行が終了した後にも実行するかどうか。ただし1度の実行中に複数回`trigger`を実行しても1度だけしか実行しない。省略した場合は`true`
	 * @returns 
	 */
	trigger(delay = true): Promise<R> | null {
		if (this.isClose) {
			return null;
		}

		if (this.promise) {
			if (delay) {
				this.hasNext = true;
			}
			return this.promise;
		}
		return this.execute();
	}

	private async execute(): Promise<R> {
		try {
			this.promise = Promise.resolve(this.func());
			return await this.promise;
		} catch (err) {
			if (this.err) {
				return await this.err(err);
			}
			throw err;
		} finally {
			this.promise = null;
			if (!this.isClose && this.hasNext) {
				this.hasNext = false;
				void this.execute();
			}
		}
	}

	async close(): Promise<void> {
		this.isClose = true;
		this.hasNext = false;
		await this.promise;
	}
}
export default ExclusiveExecution;
