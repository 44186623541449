import React, { FC, ReactNode } from 'react';

interface PageMainProps {
	title?: string;
	children: ReactNode;
}

export const PageMain: FC<PageMainProps> = (props) => {
	return (
		<div className="flex-box">
			<div className="wrapper-right">
				{props.title &&
					<h2>{props.title}</h2>
				}
				{props.children}
			</div>
		</div>
	);
}
export default PageMain;