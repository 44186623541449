import Encoding from 'encoding-japanese';

/**
 * 日本語文字列バイナリのエンコーディングを判定する。
 * encoding-japaneseのdetectの処理が時々怪しい(ShiftJISエンコーディングで0xFAB1'﨑'の字が含まれると`UNICODE`判定される)ので、対策する
 * @param bin 日本語文字列
 * @returns 判定したエンコーディング、判定できなければ`undefined`
 */
export function detectEncoding(bin: Uint8Array, hasHeader = false): Encoding.Encoding | undefined {
	// ヘッダがあれば正しく判定できる固定値の可能性が高いので、ヘッダの判定を優先する
	if (hasHeader) {
		const enc = Encoding.detect(bin.subarray(0, bin.indexOf('\n'.charCodeAt(0))));
		if (enc && enc !== 'ASCII') {
			return enc;
		}
	}

	// ヘッダで判定できなかったら全行を判定する
	const counts: Map<Encoding.Encoding | false, number> = new Map();
	let startIdx = 0;
	for (;;) {
		const i = bin.indexOf('\n'.charCodeAt(0), startIdx);
		const endIdx = i >= 0 ? i : undefined;
		const enc = Encoding.detect(bin.subarray(startIdx, endIdx));
		counts.set(enc, (counts.get(enc) ?? 0) + 1);
		if (endIdx == null) {
			break;
		}
		startIdx = endIdx + 1;
	}

	// ASCIIは大体UTF8なんよ……
	if (counts.has('ASCII')) {
		counts.set('ASCII', 0);
	}
	const rank = [...counts].sort(([, a], [, b]) => b - a);
	const top = rank[0]?.[1] !== rank[1]?.[1] ? rank[0]?.[0] : undefined;
	return top || undefined;
}
export default detectEncoding;
