import { sasagase } from "@sasagase/types";
import { doneMoving as doneMovingAPI, getMoving as getMovingAPI, storedMoving as storedMovingAPI } from "../api";
import { useAPI } from "../context";

interface UseMethods {
	getMoving: (id: string) => Promise<sasagase.Moving | undefined>;
	storedMoving: (id: string, storings: sasagase.StoreMovingItem[]) => Promise<boolean>;
	doneMoving: (id: string) => Promise<boolean>;
}

/**
 * 移動伝票の操作の関数を返す
 */
export function useMovingMethods(): UseMethods {
	const callAPI = useAPI();

	const getMoving = async (id: string) => {
		const result = await callAPI(getMovingAPI(id));
		return result.data;
	};

	const storedMoving = async (id: string, storings: sasagase.StoreMovingItem[]) => {
		try {
			await callAPI(storedMovingAPI(id, storings));
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	const doneMoving = async (id: string) => {
		try {
			await callAPI(doneMovingAPI(id));
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	return {
		getMoving,
		storedMoving,
		doneMoving,
	};
}
export default useMovingMethods;
