import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppState } from "../../../context";
import { useLogin } from '../../../hooks';
import { Button } from '../../atoms/Button';
import { ErrorMessage, Form, Input, InputPassword } from '../../organisms/Form';

const initValues = {
	name: '',
	password: '',
};
type FormValues = typeof initValues;

export const Login: React.FC = () => {
	const [state] = useAppState();
	const [loginState, { login, loginBySession }] = useLogin();
	const methods = useForm<FormValues>({
		defaultValues: initValues,
	});

	React.useEffect(() => {
		if (loginState !== undefined) {
			return;
		}

		loginBySession();
	}, []);

	const handleClickLogin: SubmitHandler<FormValues> = async (values) => {
		await login(values.name, values.password);
	};

	if (loginState === undefined) {
		return <div>Session loading...</div>;
	}

	return (
		<>
			<div style={{ margin: "1em 0 0 1em" }}>
				SASAGASE 出荷管理画面
			</div>
			<div style={{ margin: "1em 0 0 1em", minHeight: "calc(100vh - 47px)" }}>
				{state.login?.message &&
					<p className="error">{state.login.message}</p>
				}
				<Form methods={methods} onSubmit={handleClickLogin} className="formbox">
					<div className="inputArea">
						<table>
							<tbody>
								<tr>
									<td>ユーザ名</td>
									<td>
										<Input type="text" name="name" rules={{ required: 'ユーザ名を入力してください' }} />
										<ErrorMessage name="name" />
									</td>
								</tr>
								<tr>
									<td>パスワード</td>
									<td>
										<InputPassword name="password" placeholder="***********" rules={{ required: 'パスワードを入力してください' }} />
										<ErrorMessage name="password" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Button type="submit">ログイン</Button>
				</Form>
			</div>
		</>
	);
};
export default Login;