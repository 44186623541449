import { PageInfo } from '@sasagase/types';
import * as React from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';

export type CheckState = {
	checked: boolean;
	indeterminate: boolean;
};
type Checks = Record<string | number, boolean>;
export type ListSelectorContextProviderValue = {
	checks: {
		[x: string]: boolean;
		[x: number]: boolean;
	},
	checkState: CheckState;
	inputCheckAll: boolean;
	pageInfo: PageInfo | null,
	handleChange: () => void;
	handleChangeAll: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	setChecks: (checked: Checks) => void;
	setRecords: (rows: any[], pageInfo: PageInfo) => void;
} & Pick<UseFormReturn, 'register'|'getValues'>;

export const contextListSelector = React.createContext<ListSelectorContextProviderValue | null>(null);

interface ListSelectorContextProviderProps {
	children?: React.ReactNode;
}

export const ListSelectorContextProvider: React.FC<ListSelectorContextProviderProps> = (props) => {
	const { setValue, getValues, register, control } = useForm({
		shouldUnregister: false
	});
	const [checkState, setCheckState] = React.useState<CheckState>({
		checked: false,
		indeterminate: false,
	});
	const [inputCheckAll, setInputCheckAll] = React.useState<boolean>(false);
	const [pageInfo, setPageInfo] = React.useState<PageInfo|null>(null);

	const check = React.useCallback(() => {
		const checks = Object.values(getValues().check ?? {});
		const isAll = checks.length > 0 && checks.every(Boolean);
		const isPartial = !isAll && checks.some(Boolean);
		return {
			checked: isAll,
			indeterminate: isPartial,
		};
	}, [getValues]);

	const handleChange = React.useCallback(() => {
		setCheckState(prev => {
			const state = check();
			if (prev.checked == state.checked && prev.indeterminate == state.indeterminate) {
				return prev;
			}
			return state;
		});
	}, [setCheckState, check]);

	const setChecks = React.useCallback((checked: Checks) => {
		setValue('check', checked);
		handleChange();
	}, [setValue, handleChange]);

	const handleChangeAll = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
		setInputCheckAll(ev.currentTarget.checked)
		const checks = getValues().check ?? {};
		setChecks(Object.fromEntries(Object.keys(checks).map(key => [key, ev.currentTarget.checked])));
	}, [getValues, setChecks]);

	const setRecords = React.useCallback((rows: any[], pageInfo: PageInfo) => {
		const prev = getValues().check ?? {};
		const next = Object.fromEntries(rows.map(row => [row.id, inputCheckAll]));
		setChecks({ ...next, ...prev });
		setPageInfo(pageInfo);
	}, [setChecks, inputCheckAll]);

	const checks = useWatch({
		control,
		name: 'check',
		defaultValue: {},
	});
	React.useEffect(() => {
		handleChange();
	}, [checks]);

	const value = React.useMemo(() => ({
		checks,
		checkState,
		inputCheckAll,
		pageInfo,
		handleChange,
		handleChangeAll,
		setChecks,
		setRecords,
		register,
		getValues,
	}), [checks, checkState, inputCheckAll, pageInfo, handleChange, handleChangeAll, setChecks, setRecords, register, getValues]);

	return (
		<contextListSelector.Provider value={value}>
			{props.children}
		</contextListSelector.Provider>
	);
};
export default ListSelectorContextProvider;