import React, { FC, ReactNode } from 'react';

interface PageBlockProps {
	title?: string;
	children: ReactNode;
}

export const PageBlock: FC<PageBlockProps> = (props) => {
	return (
		<article>
			{props.title &&
				<h3>{props.title}</h3>
			}
			{props.children}
		</article>
	);
}
export default PageBlock;