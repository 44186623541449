import { putShippingState } from "../api";
import { useAPI } from "../context";

export function useShippingState() {
	const callAPI = useAPI();

	const changeShippingState = async (ids: number[], state: string) => {
		const params = ids.map((id) => ({ id, state }));
		const result = await callAPI(putShippingState(params))
		return result;
	};
	return {
		changeShippingState,
	};
}
export default useShippingState;
