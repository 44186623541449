import * as React from 'react';
import GlobalMenu from './GlobalMenu';

export const Footer: React.FC = () => {

	return (
		<footer id="main-footer">
			<nav id="foot-nav">
				<GlobalMenu />
			</nav>
		</footer>
	);
};
export default Footer;