import { z } from "zod";

/** 移動伝票の入庫情報(移動済みの在庫) */
export const schemaMovingStored = z.object({
	/** SKU */
	sku: z.string(),
	/**
	 * 移動元のロケーションID
	 * `null`は入荷予定や入荷未定(外部からの入庫)でinventoryテーブルでも`location_id`は`null`
	 */
	srcLocationId: z.number().nullable(),
	/**
	 * 移動先のロケーションID
	 * `null`は移動伝票の完了時に移動先なしで単純に減算させた在庫
	 */
	destLocationId: z.number().nullable(),
	/** 入庫数量 */
	quantity: z.number(),
	/** 入庫日 */
	storedDate: z.string(),
});
/** 入庫情報 */
export type MovingStored = z.infer<typeof schemaMovingStored>;

export const schemaCreateMovingStored = schemaMovingStored.omit({
	storedDate: true,
});
export type CreateMovingStored = z.infer<typeof schemaCreateMovingStored>;

/** 移動伝票のSKU明細(移動させる在庫) */
export const schemaMovingItem = z.object({
	/** SKU */
	sku: z.string(),
	/**
	 * 移動元のロケーションID
	 * nullは未指定 入庫時にmoving.srcLocationGroupIdに所属するlocationIdから選択する
	 */
	srcLocationId: z.number().nullable(),
	/** 移動数量 */
	quantity: z.number(),
});
/** 移動伝票のSKU指定明細(移動させる在庫) */
export type MovingItem = z.infer<typeof schemaMovingItem>;

/** 移動伝票 */
export const schemaMoving = z.object({
	/** 伝票ID */
	id: z.string().uuid(),
	/** 移動元ロケーショングループID, nullは入荷予定や入荷未定(外部からの入庫) */
	srcLocationGroupId: z.number().nullable(),
	/** 移動先ロケーショングループID, 入庫時にロケーションを新規作成するときの所属グループになる */
	destLocationGroupId: z.number(),
	/** SKUごとの明細 */
	items: schemaMovingItem.array(),
	/** 入庫済みの情報 */
	storedItems: schemaMovingStored.array(),
	/** 伝票番号(重複可能) */
	number: z.string(),
	/** ステータス */
	state: z.string(),
	/** 作成日 */
	createdDate: z.string(),
	/** 到着予定日 */
	arrivalDate: z.string().optional(),
	/** 完了日 */
	doneDate: z.string().optional(),
	/** 備考 */
	note: z.string(),
});
/** 移動伝票 */
export type Moving = z.infer<typeof schemaMoving>;

export const schemaCreateMoving = schemaMoving.omit({
	storedItems: true,
	createdDate: true,
}).extend({
	number: z.string().optional(),
});
export type CreateMoving = z.infer<typeof schemaCreateMoving>;

export const schemaStoreMovingItem = z.object({
	sku: z.string(),
	/** ロケーションID or ロケーション名 */
	locationKey: z.union([z.number(), z.string()]).nullable(),
	quantity: z.number(),
});
export type StoreMovingItem = z.infer<typeof schemaStoreMovingItem>;
