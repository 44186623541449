import { postOperation } from "../api";
import { useAPI } from "../context";
import { AddParamShippingOperation } from "../types";

interface UseShippingOperationMethods {
	add: (params: AddParamShippingOperation[]) => Promise<boolean>;
}

export const useShippingOperation = (): UseShippingOperationMethods => {
	const callAPI = useAPI();

	const add = async (params: AddParamShippingOperation[]) => {
		try {
			const result = await callAPI(postOperation(params));
			return Boolean(result.data);

		} catch (err) {
			console.log(err)
			return false;
		}
	};

	return {
		add
	};
};