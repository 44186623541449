import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface TextareaProps {
	name: string;
	rules?: Record<string, unknown>;
	className?: string;
	rows?: number;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
}
export const Textarea: React.FC<TextareaProps> = (props) => {
	const { register } = useFormContext();

	const { name, rules, ...inputProps } = props;

	return (
		<textarea
			{...inputProps}
			{...register(name, rules ?? {})}
			className={props.className ?? 'el_textarea'}
			rows={props.rows ?? 3} />
	);
}
export default Textarea;
