import { APIRequest } from '.';

export function userLogin(name: string, password: string): APIRequest {
	return {
		method: 'post',
		url: '/api/login',
		data: { name, password },
	};
}
export function userLoginBySession(): APIRequest {
	return {
		method: 'get',
		url: '/api/login',
	};
}
