import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	rules?: Record<string, unknown>;
	className?: string;
}
export const Input: React.FC<InputProps> = (props) => {
	const { register } = useFormContext();

	const { name, rules, ...inputProps } = props;

	return (
		<input {...inputProps} {...register(name ?? '', rules ?? {})} />
	);
}
export default Input;
