import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AxiosRequestConfigWithResponceType<T> extends AxiosRequestConfig {}
export type APIRequest<T = any> = AxiosRequestConfigWithResponceType<T>;
type APICallback<T> = (err: null|Error|AxiosResponse<T>, result: AxiosResponse<T>) => unknown;

/**
 * API呼び出しをする関数
 * 
 * レスポンスは第2引数にコールバック関数が指定されていれば、そのコールバック関数の引数として呼び出される。
 * この場合の戻り値はAPI呼び出しをキャンセルする関数になる。
 * 第2引数が省略されていた場合、戻り値はレスポンスに解決されるPromiseになる。
 * 
 * @param {APIRequest} req API呼び出しのメソッドやURL等を意味するAPIRequestオブジェクト
 * @param {APICallback} [func] レスポンス時に呼び出されるコールバック関数
 * @returns {Promise<AxiosResponse>|() => void} 
 *     第２引数が省略されていれば戻り値に解決されるPromise
 *     指定されていればAPI呼び出しをキャンセルする関数を返す
 *
 * @export
 * @interface APICaller
 */
export interface APICaller {
	<T>(req: APIRequest<T>): Promise<AxiosResponse<T>>;
	<T>(req: APIRequest<T>, func: APICallback<T>): () => void;
}
type APICallerCreator = (errorCallback: (result: AxiosResponse) => unknown) => APICaller;

export * from './inventory';
export * from './location';
export * from './moving';
export * from './product';
export * from './shipping';
export * from './store';
export * from './user';

function statusValidator(status: number): boolean {
	return status < 500;
}

const api: APICallerCreator = (errorCallback) => {
	function callAPI<T>(req: APIRequest<T>): Promise<AxiosResponse<T>>;
	function callAPI<T>(req: APIRequest<T>, func: APICallback<T>): () => void;
	function callAPI<T>(...args: [APIRequest<T>] | [APIRequest<T>, APICallback<T>]) {
		const [req, func] = args;
		const token = localStorage.getItem('token');
		const headers = token ? {
			...req.headers,
			Authorization: 'Bearer ' + token,
		} : req.headers;
		const source = axios.CancelToken.source();
		const config: AxiosRequestConfigWithResponceType<T> = {
			...req,
			headers,
			validateStatus: statusValidator,
			cancelToken: source.token,
		};

		let isDone = false;
		const p = axios(config)
			.then(result => {
				isDone = true;

				if (result.status >= 400) {
					errorCallback(result);
					return Promise.reject(result);
				}

				func?.(null, result);
				return result;
			})
			.catch(err => {
				if (axios.isCancel(err)) {
					return;
				}
				isDone = true;
				func?.(err, err);
				console.error(err);
				return Promise.reject(err);
			});
		
		if (func) {
			return () => {
				if (isDone) {
					return;
				}
				source.cancel();
			};
		} else {
			return p;
		}
	}
	return callAPI;
};
export default api;