import dayjs from 'dayjs';
import { APIRequest } from '.';
import { AddParamShippingOperation, EditShippingStateParam, PutShippingStateParam, ShippingControl } from "../types";

export function getShippings(locationGroupId: number, shippingState: string): APIRequest {
	const params = new URLSearchParams({
		search: "",
		not_yet: "",
		shippingState,
		locationGroupId: String(locationGroupId),
	});
	return {
		method: 'get',
		url: `/api/shipping/location?` + params.toString(),
	};
}

export function getShipped(locationGroupId: number, shippingState: string, shippedMonth: number): APIRequest {
	const params = new URLSearchParams({
		search: "",
		shippingState,
		locationGroupId: String(locationGroupId),
		shippedStart: String(dayjs(shippedMonth).startOf('month').valueOf()),
		shippedEnd: String(dayjs(shippedMonth).endOf('month').valueOf()),
	});
	return {
		method: 'get',
		url: `/api/shipping/location?` + params.toString(),
	};
}

export function getShippingIds(locationGroupId: number, ids: string[]): APIRequest {
	const params = new URLSearchParams({
		search: "",
		not_yet: "",
		id: ids.join(','),
		reserved: "",
	});
	return {
		method: 'get',
		url: `/api/shipping/?` + params.toString(),
	};
}

export function postOperation(params: AddParamShippingOperation[]): APIRequest {
	return {
		method: 'post',
		url: `/api/shipping/operation/`,
		data: params,
	};
}

export function putShippings(params: EditShippingStateParam[]): APIRequest {
	return {
		method: 'put',
		url: `/api/shipping/`,
		data: params,
	};
}

export function putShippingState(params: PutShippingStateParam[]): APIRequest {
	return {
		method: 'put',
		url: `/api/shipping/state`,
		data: params,
	};
}

export function postShippingCompleted(shippings: ShippingControl[], opt: { updateState: boolean, requireCheck: boolean }): APIRequest {
	const params = new URLSearchParams();
	if (opt.updateState) {
		params.append('update_state', "1");
	}
	if (opt.requireCheck) {
		params.append('require_check', "1");
	}
	return {
		method: 'post',
		url: `/api/shipping/completed/?` + params.toString(),
		data: shippings,
	};
}

export function getForShippings(ids: string[]): APIRequest {
	const params = new URLSearchParams({
		id: ids.join(','),
		reserved: "",
	});
	return {
		method: 'get',
		url: `/api/shipping/for_shipping?` + params.toString(),
	};
}