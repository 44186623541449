import { getProductBySkus } from "../api";
import { useAPI } from "../context";
import { Product } from '../types';

interface UseMethods {
	getProducts: (skus: string[]) => Promise<Product[]>;
}

/**
 * 商品情報の操作の関数を返す
 */
export function useProductMethods(): UseMethods {
	const callAPI = useAPI();

	const getProducts = async (skus: string[]) => {
		const result = await callAPI(getProductBySkus(skus));
		return result.data;
	};

	return {
		getProducts,
	};
}
export default useProductMethods;
