import ja from 'date-fns/locale/ja';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('ja', ja);

interface DatePickerProps {
	onChange: (...event: any[]) => void;
	onBlur: () => void
	value: any;
	popperClassName?: string;
	minDate?: Date;
	isClearable?: boolean;
	disabled?: boolean;
}
export const DatePicker: React.FC<DatePickerProps> = (props) => {
	return (
		<ReactDatePicker
			popperClassName={props.popperClassName}
			locale="ja"
			onChange={props.onChange}
			onBlur={props.onBlur}
			selected={props.value}
			dateFormat="yyyy/MM/dd"
			minDate={props.minDate}
			isClearable={props.isClearable}
			disabled={props.disabled} />
	);
}
export default DatePicker;
