import sleep from "./sleep";

interface RetryExecutorOptions {
	/** 実行回数 */
	count: number;

	/**
	 * 次回までの待機ミリ秒数を取得もしくは設定する関数
	 * @param nextWait 次回までの待機ミリ秒数
	 * @returns 設定した時間。引数を省略した場合は現在設定されている時間。
	 */
	next: (nextWait?: number) => number;

	/**
	 * これ以上リトライしないことを設定する関数。
	 * この関数の実行後にthrowしたエラーは、実行回数に関わらずそのままretryからthrowされる。
	 */
	done: () => void;

	/**
	 * 前回までの実行でthrowもしくはrejectされた値を実行順に格納した配列
	 */
	errors: unknown[];
}

type RetryExecutor<T> = (options: RetryExecutorOptions) => Promise<T> | T;

export interface RetryOptions {
	/** ベース待機ミリ秒数 */
	baseTime?: number;

	/** 最大待機ミリ秒数 */
	maxTime?: number;

	/** 最大実行回数 */
	maxCount?: number;

	/** 全体のタイムアウトミリ秒数 */
	timeout?: number;
}

/**
 * executorに指定した関数をthrowもしくはPromiseがrejectされなくなるまで繰り返し実行する。
 * 2回目以降はexecutorの実行前に Decorrelated jitter backoff algorithm に基づいた時間を待機する。
 * 
 * maxCountオプションが指定されている場合、指定回数実行後は最後にthrowもしくはrejectされた値をそのままthrowする。
 * timeoutオプションが指定されている場合、全体を通して指定した時間経過後はリトライ回数がmaxCount未満でもそれ以上リトライしない。
 * 
 * @see https://aws.amazon.com/jp/blogs/architecture/exponential-backoff-and-jitter/
 * @param options
 * @param executor 繰り返し実行されるコールバック関数
 * @returns
 */
export async function retry<T = unknown>(options: RetryOptions, executor: RetryExecutor<T>): Promise<T> {
	const {
		baseTime = 500,
		maxTime = Infinity,
		maxCount = Infinity,
		timeout = Infinity,
	} = options;

	if (baseTime <= 0) {
		throw new Error('baseTimeには1以上を指定してください');
	}
	if (maxTime <= 0) {
		throw new Error('maxTimeには1以上を指定してください');
	}
	if (maxCount <= 0) {
		throw new Error('maxCountには1以上を指定してください');
	}
	if (timeout <= 0) {
		throw new Error('timeoutには1以上を指定してください');
	}

	let ms = baseTime;
	let count = 0;
	const next = (nextWait = ms) => ms = nextWait;
	const done = () => {
		count = Infinity;
	};
	const errors: unknown[] = [];
	const limitTime = Date.now() + timeout;

	while (count < maxCount) {
		ms = Math.min(maxTime, Math.random() * (ms * 3 - baseTime) + baseTime);
		if (count) {
			if (Date.now() + ms > limitTime) {
				break;
			}
			await sleep(ms);
		}
		count++;

		try {
			return await executor({ count, next, done, errors });
		} catch (err) {
			errors.push(err);
		}
	}
	throw errors[errors.length - 1];
}
export default retry;
