export const ASCII_CODE_LIMIT = 0x007E;

/**
 * ASCII範囲の半角変換表
 * @see http://www.unicode.org/charts/PDF/UFF00.pdf
 * @see https://unicode.org/charts/PDF/UFE00.pdf
 */
export const ASCII_FULL_TO_HALF_MAP = new Map([
	// Fullwidth ASCII variants(ASCII 0020-007E)
	['！', '!'], // FULLWIDTH EXCLAMATION MARK
	['＂', '"'], // FULLWIDTH QUOTATION MARK
	['＃', '#'], // FULLWIDTH NUMBER SIGN
	['＄', '$'], // FULLWIDTH DOLLAR SIGN
	['％', '%'], // FULLWIDTH PERCENT SIGN
	['＆', '&'], // FULLWIDTH AMPERSAND
	['＇', '\''], // FULLWIDTH APOSTROPHE
	['（', '('], // FULLWIDTH LEFT PARENTHESIS
	['）', ')'], // FULLWIDTH RIGHT PARENTHESIS
	['＊', '*'], // FULLWIDTH ASTERISK
	['＋', '+'], // FULLWIDTH PLUS SIGN
	['，', ','], // FULLWIDTH COMMA
	['－', '-'], // FULLWIDTH HYPHEN-MINUS
	['\u2010', '-'], // HYPHEN
	['\u2011', '-'], // NON-BREAKING HYPHEN
	['\u2012', '-'], // FIGURE DASH
	['\u2013', '-'], // EN DASH
	['\u2014', '-'], // EM DASH
	['\u2015', '-'], // HORIZONTAL BAR
	['\u2212', '-'], // MINUS SIGN
	['．', '.'], // FULLWIDTH FULL STOP
	['／', '/'], // FULLWIDTH SOLIDUS
	['０', '0'], // FULLWIDTH DIGIT ZERO
	['１', '1'], // FULLWIDTH DIGIT ONE
	['２', '2'], // FULLWIDTH DIGIT TWO
	['３', '3'], // FULLWIDTH DIGIT THREE
	['４', '4'], // FULLWIDTH DIGIT FOUR
	['５', '5'], // FULLWIDTH DIGIT FIVE
	['６', '6'], // FULLWIDTH DIGIT SIX
	['７', '7'], // FULLWIDTH DIGIT SEVEN
	['８', '8'], // FULLWIDTH DIGIT EIGHT
	['９', '9'], // FULLWIDTH DIGIT NINE
	['：', ':'], // FULLWIDTH COLON
	['；', ';'], // FULLWIDTH SEMICOLON
	['＜', '<'], // FULLWIDTH LESS-THAN SIGN
	['＝', '='], // FULLWIDTH EQUALS SIGN
	['＞', '>'], // FULLWIDTH GREATER-THAN SIGN
	['？', '?'], // FULLWIDTH QUESTION MARK
	['＠', '@'], // FULLWIDTH COMMERCIAL AT
	['Ａ', 'A'], // FULLWIDTH LATIN CAPITAL LETTER A
	['Ｂ', 'B'], // FULLWIDTH LATIN CAPITAL LETTER B
	['Ｃ', 'C'], // FULLWIDTH LATIN CAPITAL LETTER C
	['Ｄ', 'D'], // FULLWIDTH LATIN CAPITAL LETTER D
	['Ｅ', 'E'], // FULLWIDTH LATIN CAPITAL LETTER E
	['Ｆ', 'F'], // FULLWIDTH LATIN CAPITAL LETTER F
	['Ｇ', 'G'], // FULLWIDTH LATIN CAPITAL LETTER G
	['Ｈ', 'H'], // FULLWIDTH LATIN CAPITAL LETTER H
	['Ｉ', 'I'], // FULLWIDTH LATIN CAPITAL LETTER I
	['Ｊ', 'J'], // FULLWIDTH LATIN CAPITAL LETTER J
	['Ｋ', 'K'], // FULLWIDTH LATIN CAPITAL LETTER K
	['Ｌ', 'L'], // FULLWIDTH LATIN CAPITAL LETTER L
	['Ｍ', 'M'], // FULLWIDTH LATIN CAPITAL LETTER M
	['Ｎ', 'N'], // FULLWIDTH LATIN CAPITAL LETTER N
	['Ｏ', 'O'], // FULLWIDTH LATIN CAPITAL LETTER O
	['Ｐ', 'P'], // FULLWIDTH LATIN CAPITAL LETTER P
	['Ｑ', 'Q'], // FULLWIDTH LATIN CAPITAL LETTER Q
	['Ｒ', 'R'], // FULLWIDTH LATIN CAPITAL LETTER R
	['Ｓ', 'S'], // FULLWIDTH LATIN CAPITAL LETTER S
	['Ｔ', 'T'], // FULLWIDTH LATIN CAPITAL LETTER T
	['Ｕ', 'U'], // FULLWIDTH LATIN CAPITAL LETTER U
	['Ｖ', 'V'], // FULLWIDTH LATIN CAPITAL LETTER V
	['Ｗ', 'W'], // FULLWIDTH LATIN CAPITAL LETTER W
	['Ｘ', 'X'], // FULLWIDTH LATIN CAPITAL LETTER X
	['Ｙ', 'Y'], // FULLWIDTH LATIN CAPITAL LETTER Y
	['Ｚ', 'Z'], // FULLWIDTH LATIN CAPITAL LETTER Z
	['［', '['], // FULLWIDTH LEFT SQUARE BRACKET
	['＼', '\\'], // FULLWIDTH REVERSE SOLIDUS
	['］', ']'], // FULLWIDTH RIGHT SQUARE BRACKET
	['＾', '^'], // FULLWIDTH CIRCUMFLEX ACCENT
	['＿', '_'], // FULLWIDTH LOW LINE
	['｀', '`'], // FULLWIDTH GRAVE ACCENT
	['ａ', 'a'], // FULLWIDTH LATIN SMALL LETTER A
	['ｂ', 'b'], // FULLWIDTH LATIN SMALL LETTER B
	['ｃ', 'c'], // FULLWIDTH LATIN SMALL LETTER C
	['ｄ', 'd'], // FULLWIDTH LATIN SMALL LETTER D
	['ｅ', 'e'], // FULLWIDTH LATIN SMALL LETTER E
	['ｆ', 'f'], // FULLWIDTH LATIN SMALL LETTER F
	['ｇ', 'g'], // FULLWIDTH LATIN SMALL LETTER G
	['ｈ', 'h'], // FULLWIDTH LATIN SMALL LETTER H
	['ｉ', 'i'], // FULLWIDTH LATIN SMALL LETTER I
	['ｊ', 'j'], // FULLWIDTH LATIN SMALL LETTER J
	['ｋ', 'k'], // FULLWIDTH LATIN SMALL LETTER K
	['ｌ', 'l'], // FULLWIDTH LATIN SMALL LETTER L
	['ｍ', 'm'], // FULLWIDTH LATIN SMALL LETTER M
	['ｎ', 'n'], // FULLWIDTH LATIN SMALL LETTER N
	['ｏ', 'o'], // FULLWIDTH LATIN SMALL LETTER O
	['ｐ', 'p'], // FULLWIDTH LATIN SMALL LETTER P
	['ｑ', 'q'], // FULLWIDTH LATIN SMALL LETTER Q
	['ｒ', 'r'], // FULLWIDTH LATIN SMALL LETTER R
	['ｓ', 's'], // FULLWIDTH LATIN SMALL LETTER S
	['ｔ', 't'], // FULLWIDTH LATIN SMALL LETTER T
	['ｕ', 'u'], // FULLWIDTH LATIN SMALL LETTER U
	['ｖ', 'v'], // FULLWIDTH LATIN SMALL LETTER V
	['ｗ', 'w'], // FULLWIDTH LATIN SMALL LETTER W
	['ｘ', 'x'], // FULLWIDTH LATIN SMALL LETTER X
	['ｙ', 'y'], // FULLWIDTH LATIN SMALL LETTER Y
	['ｚ', 'z'], // FULLWIDTH LATIN SMALL LETTER Z
	['｛', '{'], // FULLWIDTH LEFT CURLY BRACKET
	['｜', '|'], // FULLWIDTH VERTICAL LINE
	['｝', '}'], // FULLWIDTH RIGHT CURLY BRACKET
	['～', '~'], // FULLWIDTH TILDE
	['〜', '~'], // wave dash
	// 異体字セレクタ
	['\uFE00', ''],
	['\uFE01', ''],
	['\uFE02', ''],
	['\uFE03', ''],
	['\uFE04', ''],
	['\uFE05', ''],
	['\uFE06', ''],
	['\uFE07', ''],
	['\uFE08', ''],
	['\uFE09', ''],
	['\uFE0A', ''],
	['\uFE0B', ''],
	['\uFE0C', ''],
	['\uFE0D', ''],
	['\uFE0E', ''],
	['\uFE0F', ''],
]);

/** ASCII範囲以外の半角変換表 */
export const NOT_ASCII_FULL_TO_HALF_MAP = new Map([
	// Fullwidth brackets
	// ['｟', '⦅'], // FULLWIDTH LEFT WHITE PARENTHESIS
	// ['⸨', '⦅'], // left double parenthesis
	// ['｠', '⦆'], // FULLWIDTH RIGHT WHITE PARENTHESIS
	// Halfwidth CJK punctuation(CJK punctuation 3000-303F)
	['。', '｡'], // HALFWIDTH IDEOGRAPHIC FULL STOP
	['「', '｢'], // HALFWIDTH LEFT CORNER BRACKET
	['」', '｣'], // HALFWIDTH RIGHT CORNER BRACKET
	['、', '､'], // HALFWIDTH IDEOGRAPHIC COMMA
	['・', '･'], // HALFWIDTH KATAKANA MIDDLE DOT
	// Halfwidth Katakana variants(Katakana 30A0-30FF)
	['ヲ', 'ｦ'], // HALFWIDTH KATAKANA LETTER WO
	['ァ', 'ｧ'], // HALFWIDTH KATAKANA LETTER SMALL A
	['ィ', 'ｨ'], // HALFWIDTH KATAKANA LETTER SMALL I
	['ゥ', 'ｩ'], // HALFWIDTH KATAKANA LETTER SMALL U
	['ェ', 'ｪ'], // HALFWIDTH KATAKANA LETTER SMALL E
	['ォ', 'ｫ'], // HALFWIDTH KATAKANA LETTER SMALL O
	['ャ', 'ｬ'], // HALFWIDTH KATAKANA LETTER SMALL YA
	['ュ', 'ｭ'], // HALFWIDTH KATAKANA LETTER SMALL YU
	['ョ', 'ｮ'], // HALFWIDTH KATAKANA LETTER SMALL YO
	['ッ', 'ｯ'], // HALFWIDTH KATAKANA LETTER SMALL TU
	['ー', 'ｰ'], // HALFWIDTH KATAKANA-HIRAGANA PROLONGED SOUND MARK
	['ア', 'ｱ'], // HALFWIDTH KATAKANA LETTER A
	['イ', 'ｲ'], // HALFWIDTH KATAKANA LETTER I
	['ウ', 'ｳ'], // HALFWIDTH KATAKANA LETTER U
	['エ', 'ｴ'], // HALFWIDTH KATAKANA LETTER E
	['オ', 'ｵ'], // HALFWIDTH KATAKANA LETTER O
	['カ', 'ｶ'], // HALFWIDTH KATAKANA LETTER KA
	['キ', 'ｷ'], // HALFWIDTH KATAKANA LETTER KI
	['ク', 'ｸ'], // HALFWIDTH KATAKANA LETTER KU
	['ケ', 'ｹ'], // HALFWIDTH KATAKANA LETTER KE
	['コ', 'ｺ'], // HALFWIDTH KATAKANA LETTER KO
	['サ', 'ｻ'], // HALFWIDTH KATAKANA LETTER SA
	['シ', 'ｼ'], // HALFWIDTH KATAKANA LETTER SI
	['ス', 'ｽ'], // HALFWIDTH KATAKANA LETTER SU
	['セ', 'ｾ'], // HALFWIDTH KATAKANA LETTER SE
	['ソ', 'ｿ'], // HALFWIDTH KATAKANA LETTER SO
	['タ', 'ﾀ'], // HALFWIDTH KATAKANA LETTER TA
	['チ', 'ﾁ'], // HALFWIDTH KATAKANA LETTER TI
	['ツ', 'ﾂ'], // HALFWIDTH KATAKANA LETTER TU
	['テ', 'ﾃ'], // HALFWIDTH KATAKANA LETTER TE
	['ト', 'ﾄ'], // HALFWIDTH KATAKANA LETTER TO
	['ナ', 'ﾅ'], // HALFWIDTH KATAKANA LETTER NA
	['ニ', 'ﾆ'], // HALFWIDTH KATAKANA LETTER NI
	['ヌ', 'ﾇ'], // HALFWIDTH KATAKANA LETTER NU
	['ネ', 'ﾈ'], // HALFWIDTH KATAKANA LETTER NE
	['ノ', 'ﾉ'], // HALFWIDTH KATAKANA LETTER NO
	['ハ', 'ﾊ'], // HALFWIDTH KATAKANA LETTER HA
	['ヒ', 'ﾋ'], // HALFWIDTH KATAKANA LETTER HI
	['フ', 'ﾌ'], // HALFWIDTH KATAKANA LETTER HU
	['ヘ', 'ﾍ'], // HALFWIDTH KATAKANA LETTER HE
	['ホ', 'ﾎ'], // HALFWIDTH KATAKANA LETTER HO
	['マ', 'ﾏ'], // HALFWIDTH KATAKANA LETTER MA
	['ミ', 'ﾐ'], // HALFWIDTH KATAKANA LETTER MI
	['ム', 'ﾑ'], // HALFWIDTH KATAKANA LETTER MU
	['メ', 'ﾒ'], // HALFWIDTH KATAKANA LETTER ME
	['モ', 'ﾓ'], // HALFWIDTH KATAKANA LETTER MO
	['ヤ', 'ﾔ'], // HALFWIDTH KATAKANA LETTER YA
	['ユ', 'ﾕ'], // HALFWIDTH KATAKANA LETTER YU
	['ヨ', 'ﾖ'], // HALFWIDTH KATAKANA LETTER YO
	['ラ', 'ﾗ'], // HALFWIDTH KATAKANA LETTER RA
	['リ', 'ﾘ'], // HALFWIDTH KATAKANA LETTER RI
	['ル', 'ﾙ'], // HALFWIDTH KATAKANA LETTER RU
	['レ', 'ﾚ'], // HALFWIDTH KATAKANA LETTER RE
	['ロ', 'ﾛ'], // HALFWIDTH KATAKANA LETTER RO
	['ワ', 'ﾜ'], // HALFWIDTH KATAKANA LETTER WA
	['ン', 'ﾝ'], // HALFWIDTH KATAKANA LETTER N
	['\u3099', 'ﾞ'], // COMBINING HALFWIDTH KATAKANA VOICED SOUND MARK
	['\u309a', 'ﾟ'], // COMBINING HALFWIDTH KATAKANA SEMI-VOICED SOUND MARK
	['゛', 'ﾞ'], // HALFWIDTH KATAKANA VOICED SOUND MARK
	['゜', 'ﾟ'], // HALFWIDTH KATAKANA SEMI-VOICED SOUND MARK
	['ガ', 'ｶﾞ'],
	['ギ', 'ｷﾞ'],
	['グ', 'ｸﾞ'],
	['ゲ', 'ｹﾞ'],
	['ゴ', 'ｺﾞ'],
	['ザ', 'ｻﾞ'],
	['ジ', 'ｼﾞ'],
	['ズ', 'ｽﾞ'],
	['ゼ', 'ｾﾞ'],
	['ゾ', 'ｿﾞ'],
	['ダ', 'ﾀﾞ'],
	['ヂ', 'ﾁﾞ'],
	['ヅ', 'ﾂﾞ'],
	['デ', 'ﾃﾞ'],
	['ド', 'ﾄﾞ'],
	['バ', 'ﾊﾞ'],
	['ビ', 'ﾋﾞ'],
	['ブ', 'ﾌﾞ'],
	['ベ', 'ﾍﾞ'],
	['ボ', 'ﾎﾞ'],
	['パ', 'ﾊﾟ'],
	['ピ', 'ﾋﾟ'],
	['プ', 'ﾌﾟ'],
	['ペ', 'ﾍﾟ'],
	['ポ', 'ﾎﾟ'],
	['ヴ', 'ｳﾞ'],
	['ヷ', 'ﾜﾞ'],
	['ヺ', 'ｦﾞ'],
	// Halfwidth Hangul variants(Hangul Compatibility Jamo 3130-318F)
	// ['ㅤ', ''], // HALFWIDTH HANGUL FILLER
	// ['ㄱ', 'ﾡ'], // HALFWIDTH HANGUL LETTER KIYEOK
	// ['ㄲ', 'ﾢ'], // HALFWIDTH HANGUL LETTER SSANGKIYEOK
	// ['ㄳ', 'ﾣ'], // HALFWIDTH HANGUL LETTER KIYEOK-SIOS
	// ['ㄴ', 'ﾤ'], // HALFWIDTH HANGUL LETTER NIEUN
	// ['ㄵ', 'ﾥ'], // HALFWIDTH HANGUL LETTER NIEUN-CIEUC
	// ['ㄶ', 'ﾦ'], // HALFWIDTH HANGUL LETTER NIEUN-HIEUH
	// ['ㄷ', 'ﾧ'], // HALFWIDTH HANGUL LETTER TIKEUT
	// ['ㄸ', 'ﾨ'], // HALFWIDTH HANGUL LETTER SSANGTIKEUT
	// ['ㄹ', 'ﾩ'], // HALFWIDTH HANGUL LETTER RIEUL
	// ['ㄺ', 'ﾪ'], // HALFWIDTH HANGUL LETTER RIEUL-KIYEOK
	// ['ㄻ', 'ﾫ'], // HALFWIDTH HANGUL LETTER RIEUL-MIEUM
	// ['ㄼ', 'ﾬ'], // HALFWIDTH HANGUL LETTER RIEUL-PIEUP
	// ['ㄽ', 'ﾭ'], // HALFWIDTH HANGUL LETTER RIEUL-SIOS
	// ['ㄾ', 'ﾮ'], // HALFWIDTH HANGUL LETTER RIEUL-THIEUTH
	// ['ㄿ', 'ﾯ'], // HALFWIDTH HANGUL LETTER RIEUL-PHIEUPH
	// ['ㅀ', 'ﾰ'], // HALFWIDTH HANGUL LETTER RIEUL-HIEUH
	// ['ㅁ', 'ﾱ'], // HALFWIDTH HANGUL LETTER MIEUM
	// ['ㅂ', 'ﾲ'], // HALFWIDTH HANGUL LETTER PIEUP
	// ['ㅃ', 'ﾳ'], // HALFWIDTH HANGUL LETTER SSANGPIEUP
	// ['ㅄ', 'ﾴ'], // HALFWIDTH HANGUL LETTER PIEUP-SIOS
	// ['ㅅ', 'ﾵ'], // HALFWIDTH HANGUL LETTER SIOS
	// ['ㅆ', 'ﾶ'], // HALFWIDTH HANGUL LETTER SSANGSIOS
	// ['ㅇ', 'ﾷ'], // HALFWIDTH HANGUL LETTER IEUNG
	// ['ㅈ', 'ﾸ'], // HALFWIDTH HANGUL LETTER CIEUC
	// ['ㅉ', 'ﾹ'], // HALFWIDTH HANGUL LETTER SSANGCIEUC
	// ['ㅊ', 'ﾺ'], // HALFWIDTH HANGUL LETTER CHIEUCH
	// ['ㅋ', 'ﾻ'], // HALFWIDTH HANGUL LETTER KHIEUKH
	// ['ㅌ', 'ﾼ'], // HALFWIDTH HANGUL LETTER THIEUTH
	// ['ㅍ', 'ﾽ'], // HALFWIDTH HANGUL LETTER PHIEUPH
	// ['ㅎ', 'ﾾ'], // HALFWIDTH HANGUL LETTER HIEUH
	// ['ㅏ', 'ￂ'], // HALFWIDTH HANGUL LETTER A
	// ['ㅐ', 'ￃ'], // HALFWIDTH HANGUL LETTER AE
	// ['ㅑ', 'ￄ'], // HALFWIDTH HANGUL LETTER YA
	// ['ㅒ', 'ￅ'], // HALFWIDTH HANGUL LETTER YAE
	// ['ㅓ', 'ￆ'], // HALFWIDTH HANGUL LETTER EO
	// ['ㅔ', 'ￇ'], // HALFWIDTH HANGUL LETTER E
	// ['ㅕ', 'ￊ'], // HALFWIDTH HANGUL LETTER YEO
	// ['ㅖ', 'ￋ'], // HALFWIDTH HANGUL LETTER YE
	// ['ㅗ', 'ￌ'], // HALFWIDTH HANGUL LETTER O
	// ['ㅘ', 'ￍ'], // HALFWIDTH HANGUL LETTER WA
	// ['ㅙ', 'ￎ'], // HALFWIDTH HANGUL LETTER WAE
	// ['ㅚ', 'ￏ'], // HALFWIDTH HANGUL LETTER OE
	// ['ㅛ', 'ￒ'], // HALFWIDTH HANGUL LETTER YO
	// ['ㅜ', 'ￓ'], // HALFWIDTH HANGUL LETTER U
	// ['ㅝ', 'ￔ'], // HALFWIDTH HANGUL LETTER WEO
	// ['ㅞ', 'ￕ'], // HALFWIDTH HANGUL LETTER WE
	// ['ㅟ', 'ￖ'], // HALFWIDTH HANGUL LETTER WI
	// ['ㅠ', 'ￗ'], // HALFWIDTH HANGUL LETTER YU
	// ['ㅡ', 'ￚ'], // HALFWIDTH HANGUL LETTER EU
	// ['ㅢ', 'ￛ'], // HALFWIDTH HANGUL LETTER YI
	// ['ㅣ', 'ￜ'], // HALFWIDTH HANGUL LETTER I
	// Fullwidth symbol variants(Latin-1 00A0-00FF)
	// ['￠', '¢'], // FULLWIDTH CENT SIGN
	// ['￡', '£'], // FULLWIDTH POUND SIGN
	// ['￢', '¬'], // FULLWIDTH NOT SIGN
	// ['￣', '¯'], // FULLWIDTH MACRON
	// ['‾', '¯'], // overline
	// ['￤', '¦'], // FULLWIDTH BROKEN BAR
	// ['￥', '¥'], // FULLWIDTH YEN SIGN
	// ['￦', '₩'], // FULLWIDTH WON SIGN
	// Halfwidth symbol variants
	// ['│', '￨'], // HALFWIDTH FORMS LIGHT VERTICAL
	// ['←', '￩'], // HALFWIDTH LEFTWARDS ARROW
	// ['↑', '￪'], // HALFWIDTH UPWARDS ARROW
	// ['→', '￫'], // HALFWIDTH RIGHTWARDS ARROW
	// ['↓', '￬'], // HALFWIDTH DOWNWARDS ARROW
	// ['■', '￭'], // HALFWIDTH BLACK SQUARE
	// ['○', '￮'], // HALFWIDTH WHITE CIRCLE
]);

/**
 * ひらがなからカタカナの変換表
 * @see http://www.unicode.org/charts/PDF/U3040.pdf
 * @see http://www.unicode.org/charts/PDF/U30A0.pdf
 */
export const HIRAGANA_TO_KATAKANA_MAP = new Map([
	['ぁ', 'ァ'], // HIRAGANA LETTER SMALL A
	['あ', 'ア'], // HIRAGANA LETTER A
	['ぃ', 'ィ'], // HIRAGANA LETTER SMALL I
	['い', 'イ'], // HIRAGANA LETTER I
	['ぅ', 'ゥ'], // HIRAGANA LETTER SMALL U
	['う', 'ウ'], // HIRAGANA LETTER U
	['ぇ', 'ェ'], // HIRAGANA LETTER SMALL E
	['え', 'エ'], // HIRAGANA LETTER E
	['ぉ', 'ォ'], // HIRAGANA LETTER SMALL O
	['お', 'オ'], // HIRAGANA LETTER O
	['か', 'カ'], // HIRAGANA LETTER KA
	['が', 'ガ'], // HIRAGANA LETTER GA
	['き', 'キ'], // HIRAGANA LETTER KI
	['ぎ', 'ギ'], // HIRAGANA LETTER GI
	['く', 'ク'], // HIRAGANA LETTER KU
	['ぐ', 'グ'], // HIRAGANA LETTER GU
	['け', 'ケ'], // HIRAGANA LETTER KE
	['げ', 'ゲ'], // HIRAGANA LETTER GE
	['こ', 'コ'], // HIRAGANA LETTER KO
	['ご', 'ゴ'], // HIRAGANA LETTER GO
	['さ', 'サ'], // HIRAGANA LETTER SA
	['ざ', 'ザ'], // HIRAGANA LETTER ZA
	['し', 'シ'], // HIRAGANA LETTER SI
	['じ', 'ジ'], // HIRAGANA LETTER ZI
	['す', 'ス'], // HIRAGANA LETTER SU
	['ず', 'ズ'], // HIRAGANA LETTER ZU
	['せ', 'セ'], // HIRAGANA LETTER SE
	['ぜ', 'ゼ'], // HIRAGANA LETTER ZE
	['そ', 'ソ'], // HIRAGANA LETTER SO
	['ぞ', 'ゾ'], // HIRAGANA LETTER ZO
	['た', 'タ'], // HIRAGANA LETTER TA
	['だ', 'ダ'], // HIRAGANA LETTER DA
	['ち', 'チ'], // HIRAGANA LETTER TI
	['ぢ', 'ヂ'], // HIRAGANA LETTER DI
	['っ', 'ッ'], // HIRAGANA LETTER SMALL TU
	['つ', 'ツ'], // HIRAGANA LETTER TU
	['づ', 'ヅ'], // HIRAGANA LETTER DU
	['て', 'テ'], // HIRAGANA LETTER TE
	['で', 'デ'], // HIRAGANA LETTER DE
	['と', 'ト'], // HIRAGANA LETTER TO
	['ど', 'ド'], // HIRAGANA LETTER DO
	['な', 'ナ'], // HIRAGANA LETTER NA
	['に', 'ニ'], // HIRAGANA LETTER NI
	['ぬ', 'ヌ'], // HIRAGANA LETTER NU
	['ね', 'ネ'], // HIRAGANA LETTER NE
	['の', 'ノ'], // HIRAGANA LETTER NO
	['は', 'ハ'], // HIRAGANA LETTER HA
	['ば', 'バ'], // HIRAGANA LETTER BA
	['ぱ', 'パ'], // HIRAGANA LETTER PA
	['ひ', 'ヒ'], // HIRAGANA LETTER HI
	['び', 'ビ'], // HIRAGANA LETTER BI
	['ぴ', 'ピ'], // HIRAGANA LETTER PI
	['ふ', 'フ'], // HIRAGANA LETTER HU
	['ぶ', 'ブ'], // HIRAGANA LETTER BU
	['ぷ', 'プ'], // HIRAGANA LETTER PU
	['へ', 'ヘ'], // HIRAGANA LETTER HE
	['べ', 'ベ'], // HIRAGANA LETTER BE
	['ぺ', 'ペ'], // HIRAGANA LETTER PE
	['ほ', 'ホ'], // HIRAGANA LETTER HO
	['ぼ', 'ボ'], // HIRAGANA LETTER BO
	['ぽ', 'ポ'], // HIRAGANA LETTER PO
	['ま', 'マ'], // HIRAGANA LETTER MA
	['み', 'ミ'], // HIRAGANA LETTER MI
	['む', 'ム'], // HIRAGANA LETTER MU
	['め', 'メ'], // HIRAGANA LETTER ME
	['も', 'モ'], // HIRAGANA LETTER MO
	['ゃ', 'ャ'], // HIRAGANA LETTER SMALL YA
	['や', 'ヤ'], // HIRAGANA LETTER YA
	['ゅ', 'ュ'], // HIRAGANA LETTER SMALL YU
	['ゆ', 'ユ'], // HIRAGANA LETTER YU
	['ょ', 'ョ'], // HIRAGANA LETTER SMALL YO
	['よ', 'ヨ'], // HIRAGANA LETTER YO
	['ら', 'ラ'], // HIRAGANA LETTER RA
	['り', 'リ'], // HIRAGANA LETTER RI
	['る', 'ル'], // HIRAGANA LETTER RU
	['れ', 'レ'], // HIRAGANA LETTER RE
	['ろ', 'ロ'], // HIRAGANA LETTER RO
	['ゎ', 'ヮ'], // HIRAGANA LETTER SMALL WA
	['わ', 'ワ'], // HIRAGANA LETTER WA
	['ゐ', 'ヰ'], // HIRAGANA LETTER WI
	['ゑ', 'ヱ'], // HIRAGANA LETTER WE
	['を', 'ヲ'], // HIRAGANA LETTER WO
	['ん', 'ン'], // HIRAGANA LETTER N
	['ゔ', 'ヴ'], // HIRAGANA LETTER VU
	['ゕ', 'ヵ'], // HIRAGANA LETTER SMALL KA
	['ゖ', 'ヶ'], // HIRAGANA LETTER SMALL KE
	['ゝ', 'ヽ'], // HIRAGANA ITERATION MARK
	['ゞ', 'ヾ'], // HIRAGANA VOICED ITERATION MARK
	['ゟ', 'ヨリ'], // HIRAGANA DIGRAPH YORI
]);

/**
 * ハイフンに似た記号のリスト
 */
const HYPHEN_LIKE_SET = new Set([
	'\u002d', // HYPHEN-MINUS
	'\u00ad', // SOFT HYPHEN
	'\u02d7', // MODIFIER LETTER MINUS SIGN
	'\u0320', // COMBINING MINUS SIGN BELOW
	'\u1173', // HANGUL JUNGSEONG EU
	'\u1680', // OGHAM SPACE MARK
	'\u1b78', // BALINESE MUSICAL SYMBOL LEFT-HAND OPEN PANG
	'\u2010', // HYPHEN
	'\u2011', // NON-BREAKING HYPHEN
	'\u2012', // FIGURE DASH
	'\u2013', // EN DASH
	'\u2014', // EM DASH
	'\u2015', // HORIZONTAL BAR
	'\u2027', // HYPHENATION POINT
	'\u2043', // HYPHEN BULLET
	'\u207B', // SUPERSCRIPT MINUS
	'\u2212', // MINUS SIGN
	'\u2500', // BOX DRAWINGS LIGHT HORIZONTAL
	'\u2501', // BOX DRAWINGS HEAVY HORIZONTAL
	'\u25ac', // BLACK RECTANGLE
	'\u2796', // HEAVY MINUS SIGN
	'\u2e3a', // TWO-EM DASH
	'\u2e3b', // THREE-EM DASH
	'\u30fc', // KATAKANA-HIRAGANA PROLONGED SOUND MARK
	'\u3161', // HANGUL LETTER EU
	'\ufe58', // SMALL EM DASH
	'\ufe63', // SMALL HYPHEN-MINUS
	'\uff0d', // FULLWIDTH HYPHEN-MINUS
	'\uff70', // HALFWIDTH KATAKANA-HIRAGANA PROLONGED SOUND MARK
	'\u{10110}', // AEGEAN NUMBER TEN
	'\u{10191}', // ROMAN UNCIA SIGN
]);

export function toHalfWidthAscii(str: string): string {
	let res = '';
	for (const char of str) {
		if (isHyphenLike(char) && (res === '' || isAscii(res[res.length - 1]) || ASCII_FULL_TO_HALF_MAP.has(res[res.length - 1]))) {
			// ASCII範囲の文字にハイフン様の記号が続いているときは半角ハイフンに置き換える
			res += '-'; // U+002d
		} else {
			res += ASCII_FULL_TO_HALF_MAP.get(char) ?? char;
		}
	}
	return res;
}

export function toHalfWidthKana(str: string): string {
	let res = '';
	for (const char of str) {
		res += NOT_ASCII_FULL_TO_HALF_MAP.get(char) ?? char;
	}
	return res;
}

export function hiraganaToKatakana(str: string): string {
	let res = '';
	for (const char of str) {
		res += HIRAGANA_TO_KATAKANA_MAP.get(char) ?? char;
	}
	return res;
}

export function isAscii(str: string, idx = 0): boolean {
	return str.charCodeAt(idx) <= ASCII_CODE_LIMIT;
}

export function isHyphenLike(char: string): boolean {
	return HYPHEN_LIKE_SET.has(char);
}

export function toHalfWidth(str: string): string {
	return toHalfWidthKana(toHalfWidthAscii(hiraganaToKatakana(str)));
}
