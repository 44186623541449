import JsBarcode from "jsbarcode";
import * as React from 'react';

type BarcodeProps = Record<string, string|number>;

export const Barcode: React.FC<BarcodeProps> = (props) => {
	const ref = React.useRef<SVGSVGElement>(null);

	React.useEffect(() => {
		JsBarcode(ref.current).init();
	}, []);

	return (
		<svg { ...props } ref={ ref } />
	);
};