import { PageInfo } from '@sasagase/types';
import * as React from 'react';
import { contextListSelector } from '../component/ListSelectorContextProvider';

type Checks = Record<string | number, boolean>;
type UseListSelector = {
	getChecks: () => Checks;
	reset: (init?: Checks) => void;
	refresh: () => void;
	setRecords: (rows: any[], pageinfo: PageInfo) => void;
	checkedNum: () => number;
};

export function useListSelector(): UseListSelector {
	const context = React.useContext(contextListSelector);

	const toBool = (value: any) => {
		if (Array.isArray(value)) {
			return Boolean(value.length);
		}
		return Boolean(value);
	};

	const getChecks = React.useCallback(() => {
		const check = context?.getValues()?.check ?? {};
		return Object.fromEntries(Object.entries(check).map(([k,v]) => ([k, toBool(v)])));
	}, [context]);

	const reset = React.useCallback((init?: Checks) => {
		if (!init) {
			const checks = getChecks();
			init = Object.fromEntries(Object.keys(checks).map(key => [key, false]));
		}
		context?.setChecks(init);
	}, [context]);

	const refresh = React.useCallback(() => {
		context?.handleChange();
	}, [context]);

	const setRecords = React.useCallback((rows: any[], pageInfo: PageInfo) => {
		context?.setRecords(rows, pageInfo);
	}, [context]);

	const checkedNum = React.useCallback(() => {
		if (!context) {
			return 0;
		}
		const checks = getChecks();
		const checked = Object.values(checks).filter(Boolean);
		const noCheck = Object.values(checks).filter(v => !v);
		const { checkState, inputCheckAll, pageInfo } = context;

		// ページング✕
		if (!pageInfo) return checked.length;
		// ページング◯｜全選択(表示)◯
		if (checkState.checked) return pageInfo.recordsTotal;
		// ページング◯｜全選択(表示)✕｜全選択(入力)◯
		if (inputCheckAll) return pageInfo.recordsTotal - noCheck.length;
		// ページング◯｜全選択(表示)✕｜全選択(入力)✕
		return checked.length;

	}, [context]);

	return {
		getChecks,
		reset,
		refresh,
		setRecords,
		checkedNum,
	};
}
export default useListSelector;