import { CSVFile } from "@sasagase/types";
import stringify from "csv-stringify";
import iconv from 'iconv-lite';
import { getBarcode as getBarcodeAPI, getInvoice as getInvoiceAPI, postShippingCompleted as postShippingCompletedAPI } from '../api';
import { useAPI } from "../context";
import { Barcode, InvoiceData, InvoiceDataMap, ShippingControl } from '../types';

type ParseDate = {
	year: string;
	mon: string;
	day: string;
	hour?: string;
	min?: string;
	sec?: string;
} | null;

export interface NameAddress {
	name?: string; // 氏名
	kana?: string; // 読みがな
	phone?: string; // 電話番号
	country?: string; // 国名
	zip?: string; // 郵便番号
	pref?: string; // 都道府県
	city?: string; // 市区郡町村
	address?: string; // 字・番地
	subaddress?: string; // 建物名・部屋番号
	company?: string; // 会社名
	section?: string; // 部署名
}

interface PostShippingCompletedResult {
	success: number;
	shipped: number;
	invalid: number;
}

interface UseMethods {
	getInvoice: (names: string[]) => Promise<InvoiceDataMap>;
	getBarcode: () => Promise<Barcode>;
	postShippingCompleted: (shippings: ShippingControl[], locationGroupId: number) => Promise<PostShippingCompletedResult>;
	save: (csv: CSVFile) => Promise<void>;
	convertToCSV: (records: any, opt: stringify.Options) => Promise<string>;
	parseDate: (date: Date | string) => ParseDate;
	parseTimezone: (timezone: string) => { begin: string, end: string, am?: boolean } | null;
	parseDeliveryDate: (date: string) => Record<string, string>;
}

export const useCSV = (): UseMethods => {
	const callAPI = useAPI();

	const getInvoice = async (names: string[]) => {
		const result = await callAPI(getInvoiceAPI(names));
		const orderMethod: { name: string, invoiceData: InvoiceData }[] = result.data;
		return Object.fromEntries(orderMethod.map(row => [row.name, row.invoiceData]))
	};

	const getBarcode = async () => {
		const result = await callAPI(getBarcodeAPI());
		return result.data;
	};

	const postShippingCompleted = async (shippings: ShippingControl[], locationGroupId: number) => {
		try {
			const opt = {
				// モール反映完了後、出荷ステータスを更新するか否か
				updateState: [
					false,	// 0: dummy
					false,	// 1: 社内	更新しない（RFID端末での出荷で出荷ステータスは変更されるため）
					true,	// 2: T2K	更新する
				][locationGroupId] ?? true,
				// 反映対象の出荷情報の出荷ステータス・出荷元の確認を行うか否か
				requireCheck: [
					false,	// 0: dummy
					false,	// 1: 社内	確認を行わない（CSVアップのタイミングでは出荷済みになっているため）
					true,	// 2: T2K	確認を行う
				][locationGroupId] ?? true,
			};
			const result = await callAPI(postShippingCompletedAPI(shippings, opt));
			return {
				success: result.data.success ?? 0,
				shipped: result.data.shipped ?? 0,
				invalid: result.data.invalid ?? 0,
			}
		} catch (err) {
			console.log(err);
			throw new Error("CSVの適用に失敗しました");
		}
	};

	const save = async (csv: CSVFile): Promise<void> => {
		const buff = iconv.encode(csv.content, csv.charset, { addBOM: csv.bom });
		const blob = new Blob([buff], { type: `text/csv; charset=${csv.charset}`});

		saveFile(csv.filename, blob);
	};

	const convertToCSV = (records: any, opt: stringify.Options): Promise<string> => {
		return new Promise(function (resolve, reject) {
			stringify.stringify(records, opt, (err, output) => {
				if (err) {
					console.log(err);
					alert("CSVの作成に失敗しました。");
					reject(err);
					return;
				}
				resolve(output);
			});
		});
	};

	const parseDate = (date: Date | string) => {
		if (date instanceof Date) {
			return {
				year: pad0(date.getFullYear(), 4),
				mon: pad0(date.getMonth() + 1),
				day: pad0(date.getDate()),
				hour: pad0(date.getHours()),
				min: pad0(date.getMinutes()),
				sec: pad0(date.getSeconds()),
			};
		}

		const [match, year, mon, day] = (date || "").match(/^\s*(\d{1,4})[-/年](\d{1,2})[-/月](\d{1,2})[日]?\s*$/) || [];
		if (! match) {
			return null;
		}
		return {
			year: pad0(year, 4),
			mon: pad0(mon),
			day: pad0(day),
		};
	};

	const parseTimezone = (timezone: string) => {
		// Yahooの「09:00-12:00」は「午前中」として判定する
		const [match, none, am, begin, end] = (timezone || "").match(/^\s*(?:(指定なし|時間指定なし)|(午前中?|AM|09:00-12:00)|(\d{1,2})(?:時?|:00)[-~～]?(\d{1,2})(?:時?|:00))\s*$/) || [];
		if (! match) {
			return null;
		}
		if (none) {
			return { begin: "", end: "" };
		}
		if (am) {
			return { begin: "08", end: "12", am: true, };
		}
		return {
			begin: pad0(begin),
			end: pad0(end),
		};
	};

	const parseDeliveryDate = (date: string) => {
		try {
			return JSON.parse(date);
		} catch (e) {
			return { date, timezone: "", shipping: "" };
		}
	};

	const saveFile = (filename: string, blob: Blob): void => {
		const url = URL.createObjectURL(blob);

		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = filename;
		anchor.click();

		setTimeout(() => URL.revokeObjectURL(url), 100);
	};

	const  pad0 = (num: number | string, len = 2): string => {
		return num.toString().padStart(len, "0");
	};

	return {
		getInvoice,
		getBarcode,
		postShippingCompleted,
		save,
		convertToCSV,
		parseDate,
		parseTimezone,
		parseDeliveryDate,
	};
};