import * as React from 'react';
import ListSelectorContextProvider from '../../ListSelectorContextProvider';
import { ShippingList as ShippingListBase } from './ShippingList';

export const ShippingList: React.FC = () => {
	return (
		<>
			<ListSelectorContextProvider>
				<ShippingListBase />
			</ListSelectorContextProvider>
		</>
	);
}
export default ShippingList;

