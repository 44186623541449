import * as React from 'react';

export const nonNegative: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
	const i = parseInt(ev.target.value, 10);
	ev.target.value = isNaN(i) ? "" : Math.max(0, i).toString();
};

export { uri } from '@sasagase/util';

export function toBR(str: string): React.ReactNode[] {
	return str.split('\n')
		.map((line, idx) => [<br key={idx} />, line])
		.flat()
		.slice(1);
}

export function isIterable<T>(obj: unknown): obj is Iterable<T> {
	if (typeof obj == 'string') {
		return false;
	}
	return typeof (obj as any)?.[Symbol.iterator] == 'function';
}

interface InsertSeparatorProps {
	separator?: React.ReactNode;
	unique?: boolean;
	comparator?: <T>(a: T, b: T) => number;
	children?: React.ReactNode;
}
export const InsertSeparator: React.FC<InsertSeparatorProps> = (props) => {
	if (props.children == null) {
		return null;
	}

	let arr =
		Array.isArray(props.children) ? props.children :
		isIterable(props.children) ? Array.from(props.children) : [props.children];
	if (props.unique) {
		arr = Array.from(new Set(arr));
	}
	if (props.comparator) {
		arr.sort(props.comparator);
	}
	const separator = props.separator || <br />;
	return (
		<>
			{arr.map((elm, idx) =>
				<React.Fragment key={(elm as any)?.key || idx}>
					{elm}
					{idx != arr.length - 1 && separator}
				</React.Fragment>
			)}
		</>
	);
};

type TimeoutHandle = ReturnType<Window['setTimeout']>;
export function useTimer(): (callback: () => void, ms: number, ...args: unknown[]) => TimeoutHandle {
	const timeIDRef = React.useRef<TimeoutHandle[]>([]);
	React.useEffect(() => {
		return (() => {
			timeIDRef.current.forEach((timeID) => clearTimeout(timeID));
		});
	}, []);
	const setTimer = React.useCallback((callback: () => void, ms: number, ...args: unknown[]) => {
		const timeID = window.setTimeout(callback, ms, ...args);
		timeIDRef.current.push(timeID);
		return timeID;
	}, []);
	return setTimer;
}

export const isKomariLocationGroup = (locationGroupId: number | undefined): boolean => {
	const komariLocationGroupId = 1;
	return locationGroupId === komariLocationGroupId;
};