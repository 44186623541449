export const METHOD_TABLE: Record<string, string> = {
	'rakuten': '楽天',
	'rakuten2': '楽天2',
	'yahoo': 'Yahoo!',
	'prefer.jp': 'prefer.jp',
	'shopify': 'Shopify',
	'amazon': 'Amazon',
	'manually': '手動登録',
	'komarijp.com': 'komarijp.com',
	'review': 'レビュー特典',
	'wowma': 'au',
	'dshopping': 'dショッピング'
};

export const SHIPPING_STATE = {
	pending: '保留',
	waiting: '出荷待ち',
	shipping: '出荷作業中',
	shipped: '出荷済み',
};

export const MOVING_STATE_LIST = ['moving', 'done'] as const;
export const MOVING_STATE: Record<string, string> = {
	'moving': '移動中',
	'done': '完了',
};