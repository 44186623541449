import { sasagase } from "@sasagase/types";
import dayjs from 'dayjs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { MOVING_STATE } from '../../../const';
import { LocationGroup } from '../../../types';
import { IPPSelector } from '../../organisms/IPPSelector';
import { Pagination } from '../../organisms/Pagination';
import { ResizableTH } from '../../organisms/ResizableTH';

interface MovingListTableProps {
	movings?: sasagase.Moving[];
	locationGroups: LocationGroup[];
}

export const MovingListTable: React.FC<MovingListTableProps> = (props) => {
	const { movings, locationGroups } = props;

	const [page, setPage] = React.useState<number>(0);
	const [IPP, setIPP] = React.useState<number|undefined>(100);

	if (!movings) {
		return <p>Loading...</p>;
	}

	const itemPerPage = IPP || movings && movings.length;
	const movingsPage = movings?.slice(page * itemPerPage, (page + 1) * itemPerPage);

	const toStoreURL = (id: string) => `/inventory/moving/${id}/store`;
	const toStateString = (state: "moving" | "done") => MOVING_STATE[state] ?? state;
	const toDateString = (date?: string) => date && dayjs(date).format('YYYY/MM/DD');
	const toLocGroupString = (locgId: number | null) => locationGroups?.find((g) => g.id === locgId)?.name ?? locgId;

	return (
		<>
			{ "検索結果 " + movings.length + "件 " }
			<div style={{float:"right"}}>
				<div className="ipp-selector">1ページの表示件数<IPPSelector ipp={ itemPerPage } setIPP={setIPP}/></div>
			</div>
			<div className="edit-table-box">
				<table className="edit-table">
					<thead>
						<tr>
							<ResizableTH width={100} name="number">
								<span>伝票番号</span>
							</ResizableTH>
							<ResizableTH width={100} name="status">
								<span>ステータス</span>
							</ResizableTH>
							<ResizableTH width={100} name="srcLocation">
								<span>移動元</span>
							</ResizableTH>
							<ResizableTH width={100} name="destLocation">
								<span>移動先</span>
							</ResizableTH>
							<ResizableTH width={100} name="createdDate">
								<span>作成日</span>
							</ResizableTH>
							<ResizableTH width={100} name="arrivalDate">
								<span>到着予定日</span>
							</ResizableTH>
							<ResizableTH width={100} name="doneDate">
								<span>完了日</span>
							</ResizableTH>
							<ResizableTH width={200} name="note">
								<span>備考</span>
							</ResizableTH>
							<ResizableTH width={100} name="operation">
								<span>操作</span>
							</ResizableTH>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{movingsPage?.map(moving =>
							<tr key={moving.id}>
								<td><span>{moving.number}</span></td>
								<td><span>{toStateString(moving.state as "moving" | "done")}</span></td>
								<td><span>{toLocGroupString(moving.srcLocationGroupId)}</span></td>
								<td><span>{toLocGroupString(moving.destLocationGroupId)}</span></td>
								<td><span>{toDateString(moving.createdDate)}</span></td>
								<td><span>{toDateString(moving.arrivalDate)}</span></td>
								<td><span>{toDateString(moving.doneDate)}</span></td>
								<td><span>{moving.note}</span></td>
								<td><span><Link to={toStoreURL(moving.id)}>入庫</Link></span></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<Pagination now={ page } maxPage={ Math.ceil(movings.length / itemPerPage) - 1 } setPage={setPage} />
		</>
	);
}
export default MovingListTable;