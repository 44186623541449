type Transposed<T, K extends (keyof T)[]> = {
	[P in keyof K]: T[K[P]][];
};

/**
 * オブジェクトの配列を指定したプロパティで転置する
 */
export function transpose<T, K extends (keyof T)[]>(objs: T[], props: K): Transposed<T, K> {
	return props.map((col) => objs.map((obj) => obj[col])) as Transposed<T, K>;
}
export default transpose;
