import * as React from 'react';
import { isKomariLocationGroup } from '../../../lib/';

const ORDER_METHOD_URL: Record<string, (idtext: string) => string> = {
	'rakuten': (idtext) => `https://order-rp.rms.rakuten.co.jp/order-rb/individual-order-detail-sc/init?orderNumber=${encodeURIComponent(idtext)}`,
	'rakuten2': (idtext) => `https://order-rp.rms.rakuten.co.jp/order-rb/individual-order-detail-sc/init?orderNumber=${encodeURIComponent(idtext)}`,
	'yahoo': (idtext) => `https://pro.store.yahoo.co.jp/pro.prefer/order/manage/detail/${encodeURIComponent(idtext)}`,
	'prefer.jp': (idtext) => `https://prefer.jp/wp-admin/post.php?action=edit&post=${encodeURIComponent(idtext.replace(/#/g, ""))}`,
	'amazon': (idtext) => `https://sellercentral.amazon.co.jp/orders-v3/order/${encodeURIComponent(idtext)}`,
};

interface OrderMethodLinkProps {
	method: string;
	idtext: string;
	shipping_id?: string;
	locationGroupId?: number;
	children?: React.ReactNode;
}

export const OrderMethodLink: React.FC<OrderMethodLinkProps> = (props) => {
	const children = React.Children.count(props.children) > 0 ? props.children : props.idtext;
	const url = ORDER_METHOD_URL[props.method]?.(props.idtext);
	if (!isKomariLocationGroup(props.locationGroupId)) {
		return <>{children}</>;
	} else if (url) {
		return <a href={url} target="_blank" rel="noopener noreferrer">{children}</a>;
	} else if (props.shipping_id) {
		return <a href={"/orders/shippings/" + props.shipping_id + "/"} target="_blank" rel="noopener noreferrer">{children}</a>;
	} else {
		return <>{children}</>;
	}
};
export default OrderMethodLink;