import * as React from 'react';
import { ShippingOrderItem } from '../../../types';

interface ItemListRowProps {
	item: ShippingOrderItem & { idx: number };
	prefix: string;
}

export const ItemListRow: React.FC<ItemListRowProps> = (props) => {
	const { item, prefix } = props;
	const name = item.name_s ?? item.name;
	const source = item.source || [];
	const quantity = item.quantity > 1 ? <span className="strong xl">{item.quantity}</span> : <>{item.quantity}</>;

	return (
		<tr>
			<td className="textC">{item.idx}</td>
			<td className="textC">{prefix}{item.sku}</td>
			<td>
				<p className="itemName">{name}</p>
				{source.length > 0 &&
					<p className="itemOptions">
						{source.map(s => <React.Fragment key={s.key}>{s.key}:<span>{s.value}</span><br /></React.Fragment>)}
					</p>
				}
			</td>
			<td className="textR">{quantity}</td>
			<td></td>
		</tr>
	);
};