export function splitArray<T, S extends T>(arr: T[], predicate: (value: T, index: number, array: T[]) => value is S): [S[], T[]];
export function splitArray<T>(arr: T[], predicate: (value: T, index: number, array: T[]) => unknown): [T[], T[]];
export function splitArray<T, _S>(arr: T[], predicate: (value: T, index: number, array: T[]) => unknown): [T[], T[]] {
	const a: T[] = [];
	const b: T[] = [];
	for (const [i, val] of arr.entries()) {
		(predicate(val, i, arr) ? a : b).push(val);
	}
	return [a, b];
}
export default splitArray;
