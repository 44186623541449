import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as React from 'react';
import { ShipDateCount } from '../../../hooks';
import { SHIP_DATE_FORMAT } from '../../../hooks/useShipping';

dayjs.extend(isSameOrBefore);

interface ShipDateLabelProps {
	shipDateCount: ShipDateCount;
}

export const ShipDateLabel: React.FC<ShipDateLabelProps> = (props) => {
	const {shipDateCount } = props;

	const today = dayjs();
	const shipDateObj = dayjs(shipDateCount.shipDate);
	const spanStyle = shipDateObj.isBefore(today) ? { color: 'red' } : {};

	return (
		<span style={spanStyle}>[{shipDateObj.format(SHIP_DATE_FORMAT)}] ({shipDateCount.count}件)</span>
	);
}
export default ShipDateLabel;