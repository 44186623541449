import * as React from 'react';
import { PageBlock } from '../../molecules/PageBlock';
import { PageMain } from '../../molecules/PageMain';

export const Dashboard: React.FC = () => {

	return (
		<PageMain title="Dashboard">
			<PageBlock>
				<section>構築中</section>
			</PageBlock>
		</PageMain>
	);
}
export default Dashboard;