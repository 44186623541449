import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const GlobalMenu: React.FC = () => {
	const location = useLocation();

	const isActive = (target: string) => {
		return location.pathname.startsWith("/" + target + "/") ? " active" : "";
	};

	return (
		<ul className="global-menu">
			<li className={ "stock" + isActive("inventory") }>
				<Link to="/inventory/moving">在庫管理</Link>
			</li>
			<li className={ "shipping" + isActive("shipping") }>
				<Link to="/shipping/">出荷管理</Link>
			</li>
		</ul>
	);
};
export default GlobalMenu;