import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

export const LoggedIn: React.FC = () => {

	return (
		<>
			<Header />
			<div id="main-container">
				<Outlet />
			</div>
			<Footer />
		</>
	);
};
export default LoggedIn;