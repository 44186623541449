import * as React from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { isProduction } from '../../../lib/isProduction';

interface FormContextValue {
	saveResult: boolean | null;
}

export const contextForm = React.createContext<FormContextValue | null>(null);

interface FormProps {
	children: React.ReactNode;
	methods: UseFormReturn<any, any, any>;
	onSubmit?: SubmitHandler<any>;
	isErrorSubmit?: boolean;
	disableBlock?: boolean;
	className?: string;
}
export const Form: React.FC<FormProps> = (props) => {
	const { handleSubmit, getValues, reset } = props.methods;

	const [saveResult, setSaveResult] = React.useState<boolean|null>(null);

	const value = React.useMemo(() => ({
		saveResult,
	}), [saveResult, setSaveResult]);

	const handleSave: SubmitHandler<any> = async (values) => {
		if (!props.onSubmit) {
			return;
		}

		setSaveResult(null);
		const res = await props.onSubmit(values);
		if (res) {
			reset(values);
		}
		setSaveResult(Boolean(res));
	};

	const handleError: SubmitErrorHandler<any> = async (error) => {
		if (!props.onSubmit || !props.isErrorSubmit) {
			return;
		}

		if (!isProduction) {
			console.log(error)
		}

		setSaveResult(null);
		const values = getValues();
		const res = await props.onSubmit(values);
		if (res) {
			reset(values);
		}
		setSaveResult(Boolean(res));
	};

	return (
		<contextForm.Provider value={value}>
			<FormProvider {...props.methods}>
				<form onSubmit={handleSubmit(handleSave, handleError)} className={props.className ?? ''}>
					{props.children}
				</form>
			</FormProvider>
		</contextForm.Provider>
	);
}
export default Form;
