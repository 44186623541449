import ja from 'date-fns/locale/ja';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('ja', ja);

interface DateTimePickerProps {
	onChange: (...event: any[]) => void;
	onBlur: () => void
	value: any;
	popperClassName?: string;
	minDate?: Date;
	disabled?: boolean;
}
export const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
	return (
		<ReactDatePicker
			popperClassName={props.popperClassName}
			locale="ja"
			onChange={props.onChange}
			onBlur={props.onBlur}
			onCalendarClose={props.onBlur}
			selected={props.value}
			showTimeSelect
			timeIntervals={10}
			timeCaption="時間"
			timeFormat="HH：mm"
			dateFormat="yyyy/MM/dd HH:mm"
			minDate={props.minDate}
			disabled={props.disabled} />
	);
}
export default DateTimePicker;
