export type PromiseWrapper<T> = () => T;

export function promiseWrapper<T>(promise: Promise<T>): () => T {
	let status = 'pending';
	let response: T;

	const suspender = promise.then(
		(value) => {
			status = 'success';
			response = value;
		},
		(error) => {
			status = 'error';
			response = error;
		}
	);

	return () => {
		switch (status) {
			case 'pending':
				throw suspender;
			case 'error':
				throw response;
			default:
				return response;
		}
	};
}