type Func<T> = () => T | Promise<T>;
type ResolveFunc<T> = (val: T | Promise<T>) => void;

interface ParallelOptions {
	max: number;
}

interface ParallelItem<T = any> {
	func: Func<T>;
	resolve: ResolveFunc<T>;
}

export class Parallel {
	queue: ParallelItem[] = [];
	set: Set<ParallelItem> = new Set();
	private _max: number;

	constructor(opt: ParallelOptions) {
		this._max = opt.max;
	}

	get max(): number {
		return this._max;
	}

	set max(val: number) {
		this._max = val;
		this._exec();
	}

	exec<T>(func: Func<T>): Promise<T> {
		const p = new Promise<T>((resolve) => {
			this.queue.push({ func, resolve });
		});
		this._exec();
		return p;
	}

	private _exec(): void {
		while (this.set.size < this.max) {
			const item = this.queue.pop();
			if (!item) {
				break;
			}
			const { func, resolve } = item;
			this.set.add(item);

			// `func`の戻り値がPromiseとは限らないのでPromise化する
			const p = new Promise((resolve, reject) => {
				try {
					const result = func();
					resolve(result);
				} catch (err) {
					reject(err);
				}
			}).finally(() => {
				this.set.delete(item);
				this._exec();
			});
			resolve(p);
		}
	}
}
export default Parallel;
