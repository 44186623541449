import { APIRequest } from '.';
import { InventorySearchConditions } from '../hooks';

export function getInventoryStates(): APIRequest {
	return {
		method: 'get',
		url: `/api/inventory/states`,
	};
}

export function getInventoryQuantity(cond: InventorySearchConditions): APIRequest {
	const { conditions, locationGroupId } = cond;
	const params = new URLSearchParams({
		search: JSON.stringify({ conditions }),
		locationGroupId: String(locationGroupId),
	});
	return {
		method: 'get',
		url: `/api/inventory/quantity?` + params.toString(),
	};
}