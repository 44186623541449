import { sasagase } from '@sasagase/types';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form, Input, Select } from '../../organisms/Form';

type FormValues = {
	destLocationId: number;
	quantity: number;
}

interface MovingStoringDialogProps {
	name?: string;
	sku: string;
	skuName?: string;
	destLocations: {
		id: number;
		name: string;
	}[];
	onStore?: (storings: sasagase.StoreMovingItem[]) => void;
	onClose?: () => void;
}

export const MovingStoringDialog: React.FC<MovingStoringDialogProps> = (props) => {
	const formMethods = useForm<FormValues>({
		defaultValues: {
			destLocationId: props.destLocations[0].id
		},
	});

	const handleSubmit: SubmitHandler<FormValues> = (values) => {
		const { destLocationId, quantity } = values;

		props.onStore?.([{
			sku: props.sku,
			locationKey: Number(destLocationId),
			quantity: Number(quantity),
		}]);
	};
	const handleClickClose = () => {
		props.onClose?.();
	};

	const selectOptions = Object.fromEntries(props.destLocations.map((loc) => [loc.id, loc.name]));

	return (
		<Form methods={formMethods} className="sku-search-form formbox" onSubmit={handleSubmit}>
			<div className="modalbox flex-box">
				<div className="wrapper-right">
					<h3>入庫</h3>
					<article>
						<section>
							<div className="flex-box wrap inputArea">
								<table>
									<tbody>
										<tr>
											<td>SKU</td>
											<td><span>{props.sku}</span></td>
										</tr>
										<tr>
											<td>商品名</td>
											<td><span>{props.skuName}</span></td>
										</tr>
										<tr>
											<td>入庫ロケーション</td>
											<td>
												<Select name="destLocationId" options={selectOptions} />
											</td>
										</tr>
										<tr>
											<td>数量</td>
											<td><Input type="text" name="quantity" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
					</article>
					<div className="flex-box submitArea">
						<button type="submit">入庫する</button>
						<button type="button" onClick={handleClickClose}>閉じる</button>
					</div>
				</div>
			</div>
		</Form>
	);
};
export default MovingStoringDialog;
