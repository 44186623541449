import { useMemo } from "react";
import { LocationGroup } from "../types";

/**
 * 指定したロケーショングループの情報を元に、ロケーションIDをキーにロケーション名を取得するMapを生成
 */
export function useLocationNameMap(locationGroups?: LocationGroup[]): Map<number | null, string> {
	const map = useMemo(() => {
		const locationName = new Map<number | null, string>([[null, '']]);
		for (const group of locationGroups ?? []) {
			for (const loc of group.locations) {
				locationName.set(loc.id, loc.name);
			}
		}
		return locationName;
	}, [locationGroups]);

	return map;
}
export default useLocationNameMap;
