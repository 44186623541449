type Mapper<T extends Record<string, any>> = (val: T[keyof T], key: string) => any;
type Mapped<T extends Record<string, any>, M extends Mapper<T>> = {
	[P in keyof T]: M extends (val: T[P], key: P) => infer U ? U : never;
};

/**
 * オブジェクトのプロパティをmapする。
 * 複雑な型は型推論できないので、受け取る変数の型を設定する必要がある。
 * 
 * ```
 * // 単純な型なら問題ない
 * const x = objectMap({ abc: {a:1}, xyz: {a:2} }, (v) => v.a);
 * x.abc; // number
 * x.xyz; // number
 * 
 * // 複雑な型は受け取る変数等に型を設定する
 * const x: ABC = objectMap(...);
 * ```
 */
export function objectMap<
	T extends Record<string, any>,
	M extends Mapper<T>,
	U extends Mapped<T, M>,
> (obj: T, mapper: M): U
{
	return Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, mapper(val, key)])) as U;
}
export default objectMap;
