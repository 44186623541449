import { CSVFile } from "@sasagase/types";
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as React from 'react';
import { PromiseWrapper, useCSV, useLogin, useSagawaCSV, useShippingOperation, useYamatoCSV } from '../../../hooks';
import { Barcode, Shipping, ShippingForShipping } from '../../../types';
import { Button } from '../../atoms/Button';
import { ShippingListTable } from './ShippingListTable';

dayjs.extend(isSameOrBefore);

interface ShippingStartProps {
	shippings: PromiseWrapper<Shipping[]>;
	reload: () => void;
	getForShippings: () => Promise<ShippingForShipping[]>;
}

export const ShippingStart: React.FC<ShippingStartProps> = (props) => {
	const { shippings, reload, getForShippings } = props;

	const [loginState] = useLogin();
	const { add } = useShippingOperation();
	const {
		getInvoice,
		getBarcode,
		save,
		convertToCSV,
		parseDate,
	} = useCSV();
	const yamatoCSV = useYamatoCSV();
	const sagawaCSV = useSagawaCSV();

	const makeCSV = async (kind: string, content: string): Promise<CSVFile> => {
		const now = parseDate(new Date());
		const kindName = {
			invoice: '送り状印刷用',
			inspect: '出荷前検品用',
		}[kind] ?? kind;
		const filename = `こまり-${kindName}-${now?.year}${now?.mon}${now?.day}${now?.hour}${now?.min}.csv`;
		return {
			filename,
			charset: 'shift_jis',
			content
		};
	};

	const handleClickDownloadCSV = (kind: string) => async () => {
		const operationType = 'csv';
		const shippingData = await shippings();

		const operations = shippingData.map(shipping => ({ id: shipping.id, type: operationType, attributes: { kind } }))
		const addResult = await add(operations);
		if (!addResult) {
			alert('操作履歴の追加に失敗しました。');
			return;
		}

		let records;
		let options;
		const barcode = await getBarcode();
		if (kind === 'invoice') {
			const orderMethods = [ ...new Set(shippingData.flatMap(shipping => shipping.orders.map(order => order.method)))];
			const invoiceDataMap = await getInvoice(orderMethods);
			if (loginState?.permission.deliveryCompany === 'yamato') {
				records = yamatoCSV.getRecords(shippingData, invoiceDataMap, barcode);
				options = yamatoCSV.getOptions();
			} else {
				records = sagawaCSV.getRecords(shippingData, invoiceDataMap, barcode);
				options = sagawaCSV.getOptions();
			}
		} else {
			records = await getRecordForShipping(barcode);
			options = {
				columns: [
					"受注番号", "SKU", "数量", "配送先氏名",
					"商品名", "支店名", "セットフラグ",
				],
				header: true,
				quoted: true,
				quoted_empty: true,
			};
		}
		const content = await convertToCSV(records, options);
		const csvFile = await makeCSV(kind, content);
		await save(csvFile);

		reload();
	};

	const getRecordForShipping = async (barcode: Barcode) => {
		const shippings = await getForShippings();

		const records = shippings.flatMap(shipping => {
			return shipping.items.map(item => {
				return {
					"受注番号": `${barcode.shippingPrefix}${shipping.id}`,
					"SKU": `${barcode.skuPrefix}${item.sku}`,
					"数量": item.quantity,
					"配送先氏名": shipping.dest,
					"商品名": item.name,
					"支店名": "株式会社こまり",
					"セットフラグ": "非セット",
				};
			});
		});
		return records.filter(Boolean);
	};

	const handleClickPrint = (kind: string) => async () => {
		const operationType = 'print';
		const shippingData = await shippings();

		const operations = shippingData.map(shipping => ({ id: shipping.id, type: operationType, attributes: { kind } }))
		const addResult = await add(operations);
		if (!addResult) {
			alert('操作履歴の追加に失敗しました。');
			return;
		}

		const barcode = await getBarcode();
		const params = new URLSearchParams({
			...barcode,
		});
		shippingData.forEach(s => params.append('ids', String(s.id)));
		const url = `/print/${kind}?` + params.toString();
		window.open(url);

		reload();
	};

	return (
		<div className="flex-box">
			<div className="wrapper-right">
				<h2>出荷作業</h2>
				<article>
					<section>
						<p>以下の出荷情報の出荷作業を開始します。</p>
						<div className="shippingMenuBase is_active">
							<div className="row">
								<div className="col">
									<div>帳票作成</div>
									<div style={{textAlign: 'right'}}>
										<Button onClick={handleClickPrint('totalPicking')}>トータルピッキングリスト</Button>
										<Button onClick={handleClickPrint('picking')}>ピッキングリスト</Button>
									</div>
								</div>
								<div className="col">
									<div>CSV作成</div>
									<div style={{textAlign: 'right'}}>
										<Button onClick={handleClickDownloadCSV('invoice')}>送り状印刷用</Button>
										<Button onClick={handleClickDownloadCSV('inspect')}>出荷前検品用</Button>
									</div>
								</div>
							</div>
						</div>
					</section>
				</article>
				<article>
					<section>
						<h2>出荷作業対象</h2>
						<React.Suspense fallback={<p>Loading...</p>}>
							<ShippingListTable shippings={shippings} noCheckbox />
						</React.Suspense>
					</section>
				</article>
			</div>
		</div>
	);
}
export default ShippingStart;