import * as React from 'react';
import { LocationGroup, User } from '../types';
import { ContextState, LoginState } from "./state";

type SetAction = React.SetStateAction<ContextState>;
export function userSetter(user: User | null): SetAction {
	return prev => ({ ...prev, user });
}
export function loginSetter(login: LoginState | null): SetAction {
	return prev => ({ ...prev, login });
}
export function loginMessageSetter(message: string): SetAction {
	return prev => ({ ...prev, login: { message } });
}
export function userLogoutSetter(): SetAction {
	return { user: null, login: { message: 'ログアウトしました' } };
}
export function locationGroupSetter(locationGroup: LocationGroup | null): SetAction {
	return prev => ({ ...prev, locationGroup });
}

export function setParams(params: unknown): SetAction {
	return prev => ({ ...prev, params });
}
export function setModal(modal: boolean): SetAction {
	return prev => ({ ...prev, modal });
}