import dayjs from 'dayjs';
import * as React from 'react';
import { METHOD_TABLE, SHIPPING_STATE as SHIPPING_STATE_BASE } from '../../../const';
import { PromiseWrapper } from '../../../hooks';
import { SHIP_DATE_FORMAT } from '../../../hooks/useShipping';
import { Shipping } from '../../../types';
import { contextListSelector } from '../../ListSelectorContextProvider';
import { OrderMethodLink } from '../../molecules/OrderMethodLink';
import { IPPSelector } from '../../organisms/IPPSelector';
import { Pagination } from '../../organisms/Pagination';
import { ResizableTH } from '../../organisms/ResizableTH';

// このページでは保留のステータスは扱わないので除外する
const SHIPPING_STATE = Object.fromEntries(Object.entries(SHIPPING_STATE_BASE).filter(([k]) => k !== 'pending'));

interface ShippingListTableProps {
	shippings: PromiseWrapper<Shipping[]>;
	searchShippingState?: string;
	locationGroupId?: number;
	noCheckbox?: boolean;
}

export const ShippingListTable: React.FC<ShippingListTableProps> = (props) => {
	const { shippings, searchShippingState, locationGroupId, noCheckbox } = props;
	const showCheckbox = !noCheckbox;
	const list = shippings();

	const context = React.useContext(contextListSelector);
	const checkAll = React.useRef<null|HTMLInputElement>(null);
	const [page, setPage] = React.useState<number>(0);
	const [IPP, setIPP] = React.useState<number|undefined>(100);

	React.useEffect(() => {
		if (!checkAll.current || !context) {
			return;
		}
		checkAll.current.checked = context.checkState.checked;
		checkAll.current.indeterminate = context.checkState.indeterminate;
	}, [checkAll.current, context?.checkState]);

	if (!context) {
		return null;
	}

	const getOrderIdtext = (method: string, idtext: string) => {
		const methodName = METHOD_TABLE[method] || method;
		return <>{ methodName } <OrderMethodLink method={method} idtext={idtext} locationGroupId={locationGroupId} /></>;
	};

	const itemPerPage = IPP || list && list.length;

	return (
		<>
			{ "検索結果 " + list.length + "件 " }
			<div style={{float:"right"}}>
				<div className="ipp-selector">1ページの表示件数<IPPSelector ipp={ itemPerPage } setIPP={setIPP}/></div>
			</div>
			<div className="edit-table-box">
				<table className="edit-table">
					<thead>
						<tr>
							{showCheckbox &&
								<th style={{width:40}} className="noresize">
									<input type="checkbox" name="checkAll" ref={checkAll} onChange={context.handleChangeAll} />
								</th>
							}
							<ResizableTH width={ 80 } name="shipping_id">
								<span>出荷番号</span>
							</ResizableTH>
							<ResizableTH width={ 120 } name="shipping_state">
								<span>出荷ステータス</span>
							</ResizableTH>
							<ResizableTH width={ 120 } name="ship_date">
								<span>{searchShippingState === 'shipped' ? `出荷完了日` : `出荷予定日`}</span>
							</ResizableTH>
							<ResizableTH width={ 300 } name="textid">
								<span>受注番号</span>
							</ResizableTH>
							<ResizableTH width={ 180 } name="orderer">
								<span>注文者氏名</span>
							</ResizableTH>
							<ResizableTH width={ 100 } name="totalPicking">
								<span>トータル<br />ピッキング<br />リスト</span>
							</ResizableTH>
							<ResizableTH width={ 100 } name="picking">
								<span>ピッキング<br />リスト</span>
							</ResizableTH>
							<ResizableTH width={ 80 } name="invoiceCSV">
								<span>送り状<br />印刷用</span>
							</ResizableTH>
							<ResizableTH width={ 80 } name="inspectCSV">
								<span>出荷前<br />検品用</span>
							</ResizableTH>
							<th>
							</th>
						</tr>
					</thead>
					<tbody>
						{list.slice(page * itemPerPage, (page + 1) * itemPerPage).map(shipping =>
							<tr key={shipping.id}>
								{showCheckbox &&
									<td><input type="checkbox" {...context.register(`check.${shipping.id}`)} onChange={context.handleChange} /></td>
								}
								<td className="right">
									<span>{ shipping.id }</span>
								</td>
								<td>
									<span>{SHIPPING_STATE[shipping.shippingState]}</span>
								</td>
								<td>
									{searchShippingState === 'shipped' &&
										<span>{dayjs(shipping.shipping_date).format(SHIP_DATE_FORMAT)}</span>
									}
									{searchShippingState !== 'shipped' &&
										<span>{dayjs(shipping.estimatedShippingDate).format(SHIP_DATE_FORMAT)}</span>
									}
								</td>
								<td>
									{ shipping.orders.map( (order, idx) => <span key={ idx }>{ getOrderIdtext(order.method, order.idtext) }<br /></span> ) }
								</td>
								<td>
									{ shipping.orders.map( (order, idx) => <span key={ idx }>{ order.orderer.name }<br /></span> ) }
								</td>
								<td><span>{ shipping.operations.some(op => op.operation_type === 'print' &&op.attributes.kind === 'totalPicking') ? '済' : '未' }</span></td>
								<td><span>{ shipping.operations.some(op => op.operation_type === 'print' &&op.attributes.kind === 'picking') ? '済' : '未' }</span></td>
								<td><span>{ shipping.operations.some(op => op.operation_type === 'csv' &&op.attributes.kind === 'invoice') ? '済' : '未' }</span></td>
								<td><span>{ shipping.operations.some(op => op.operation_type === 'csv' &&op.attributes.kind === 'inspect') ? '済' : '未' }</span></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<Pagination now={ page } maxPage={ Math.ceil(list.length / itemPerPage) - 1 } setPage={setPage} />
		</>
	);
}
export default ShippingListTable;