import iconv from 'iconv-lite';
import { isAscii, toHalfWidthAscii, toHalfWidthKana } from "./half-full-width-form";

/** 文字列`str`を`encode`で指定した文字コードに変換した時のバイト数を返す */
function getConvLen(str: string, encode: string): number {
	return iconv.encode(str, encode).length;
}

/** 両端の空白を削除して、文字列中の連続する空白文字を単一のスペースに置き換え */
function spaceTrim(str: string): string {
	return str.trim().replace(/\s+/g, ' ');
}

/**
 * `val`で指定した文字列に含まれる全角文字を半角文字に置き換える。
 * `encode`に指定した文字コードに変換した時に`limit`で指定したバイト数以下にならなければ`null`を返す。
 */
function optimizeAddressValue(val: string, limit: number, encode: string, useHalf = true): string | null {
	if (!useHalf) {
		const noHalfAscii = spaceTrim(val);
		if (getConvLen(noHalfAscii, encode) <= limit) {
			return noHalfAscii;
		}
		return null;
	}

	const halfedAscii = toHalfWidthAscii(spaceTrim(val));
	if (getConvLen(halfedAscii, encode) <= limit) {
		return halfedAscii;
	}
	const halfedKana = toHalfWidthKana(halfedAscii);
	if (getConvLen(halfedKana, encode) <= limit) {
		return halfedKana;
	}
	return null;
}

/** 文字列を結合する。ASCII文字が続くとき、もしくは非ASCII文字同士が続くときは間にスペースを挿入する。 */
function spacingConcat(...strs: string[]): string {
	let res = '';
	for (const str of strs) {
		if (res.length > 0 && (isAscii(str) || !isAscii(res, res.length - 1))) {
			res += ' ';
		}
		res += str;
	}
	return res;
}

/** number配列で最後の0でない要素のインデックスを返す。見つからなかったら 0 を返す。 */
function findLastIndex(arr: number[]): number {
	if (arr.length <= 0) {
		return 0;
	}

	let idx = arr.length - 1;
	while (idx && !arr[idx]) {
		idx--;
	}
	return idx;
}

/** 住所の文字列を適度な箇所で分割する */
function splitParts(val: string): string[] {
	// 空白もしくは、ASCIIに非ASCII文字が続く位置で分割するが、その限りというわけでもない
	return val.trim().split(/\s+|(?<=[\x21-\x7e]|\d番(?!地)|\d号(?!室)|\d号室|\d番地|丁目|[。｡])(?![\x21-\x7e]|号|号室|番|番地|丁目|･|棟)|(?<=[-\d])(?=[a-zA-Z]{3,})/);
}

/** `val`で指定した住所の文字列を`limits`で指定したバイト数以下になるように分割する。バイト数は`encode`で指定した文字コードで算出する */
function splitAddress(val: string, limits: number[], encode: string): string[] {
	const parts = splitParts(val);

	const addrs: string[] = [];
	for (const limit of limits) {
		let addr = '';
		while (parts.length) {
			const cat = spacingConcat(addr, parts[0]);
			if (getConvLen(cat, encode) > limit) {
				break;
			}
			addr = cat;
			parts.shift();
		}
		addrs.push(addr);
	}
	if (parts.length) {
		const idx = findLastIndex(limits);
		addrs[idx] = spacingConcat(addrs[idx], ...parts);
	}
	return addrs;
}

/**
 * 住所の各要素(都道府県、市町村...など)で分割した文字列の配列`values`を、それぞれが`limits`で指定したバイト数以下になるように調整する。
 * バイト数は`encode`で指定した文字コードで算出する。
 * 調整は、全角を半角に変換したり、前後の要素に文字列を移動したりしておこなう。
 * @param values 住所の各要素(都道府県、市町村...など)で分割した文字列の配列
 * @param limits 調整後の各要素の最大バイト数の配列
 * @param encode バイト数の算出に用いる文字コード
 * @param useHalf 半角文字使用フラグ
 * @returns 調整後の住所の各要素の配列
 */
export function adjustAddress(values: string[], limits: number[], encode = 'utf-8', useHalf = true): string[] {
	const res: string[] = [];
	for (const [idx, val] of values.entries()) {
		const optimized = optimizeAddressValue(val, limits[idx], encode, useHalf);
		if (optimized === null) {
			// 項目単位の調整でも文字数オーバーするなら、すべての項目を結合して再分割したものを返す
			const joined = useHalf ? toHalfWidthKana(toHalfWidthAscii(values.join(' '))) : values.join(' ');
			return splitAddress(joined, limits, encode);
		}
		res.push(optimized);
	}
	return res;
}
export default adjustAddress;
