/**
 * `ms`で指定した時間までに`func`の戻り値のPromiseが解決されなければ、`timeoutVal`で指定した値でPromiseをrejectする
 * @param func 実行する関数
 * @param ms タイムアウト時間[ms]
 * @param timeoutVal タイムアウト時にrejectする値
 * @returns `func`の戻り値
 */
export function timeout<T>(func: () => Promise<T>, ms: number, timeoutVal?: unknown): Promise<T> {
	return new Promise((resolve, reject) => {
		const timer = setTimeout(reject, ms, timeoutVal);
		func().then((res) => {
			clearTimeout(timer);
			resolve(res);
		}).catch((err) => {
			clearTimeout(timer);
			reject(err);
		});
	});
}
export default timeout;
