import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface InputPasswordProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	rules?: Record<string, unknown>;
}

export const InputPassword: React.FC<InputPasswordProps> = (props) => {
	const { register } = useFormContext();
	const [pointerId, setPointerId] = React.useState<number | null>(null);
	const isActive = pointerId !== null;

	const { name, rules, ...inputProps } = props;

	const handlePointerDown = (ev: React.PointerEvent) => {
		if (ev.button === 0) {
			setPointerId(ev.pointerId);
		}
	};
	const handlePointerUp = (ev: React.PointerEvent) => {
		if (ev.pointerId === pointerId) {
			setPointerId(null);
		}
	};
	const handleContextMenu = (ev: React.MouseEvent) => {
		if (ev.nativeEvent instanceof PointerEvent && ev.nativeEvent.pointerType === 'touch') {
			// タッチパネル長押しでメニュー表示されがちなので、それだけキャンセルする
			ev.preventDefault();
		}
	};
	return (
		<div className="bl_password">
			<input type={isActive ? 'text' : 'password'} {...inputProps} {...register(name ?? '', rules ?? {})} />
			<span className={`el_showPassword ${isActive ? 'active' : ''}`}
				onPointerDown={handlePointerDown}
				onPointerUp={handlePointerUp}
				onPointerCancel={handlePointerUp}
				onPointerLeave={handlePointerUp}
				onContextMenu={handleContextMenu}
			></span>
		</div>
	);
}
export default InputPassword;