import { sasagase } from "@sasagase/types";
import { throwExp } from "@sasagase/util";
import dayjs from "dayjs";
import * as Encoding from "encoding-japanese";
import React, { useEffect, useState } from "react";
import { FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { MOVING_STATE } from '../../../const';
import { useCSV, useLocationGroup, useLocationNameMap, useMovingMethods, useProductMethods } from '../../../hooks';
import detectEncoding from "../../../lib/detect-encoding";
import enCompare from "../../../lib/enCompare";
import { LocationGroup } from '../../../types';
import { Overlay } from '../../molecules/Overlay';
import { PageBlock } from '../../molecules/PageBlock';
import { PageMain } from '../../molecules/PageMain';
import { Form, Input, InputDate, Select, Textarea } from '../../organisms/Form';
import { ResizableTH } from '../../organisms/ResizableTH';
import { MovingStoringDialog } from './MovingStoringDialog';

interface DatFile {
	date: Date;
	location: string;	// "SKU-00026616"
	sku: string;	// "SKU-00025952"
	quantity: number;	// 1
	processDate: Date;
}

type FormValues = {
	id: string;
	number: string;
	srcLocationGroupId: string;
	destLocationGroupId: string;
	state: string;
	skus: {
		sku: string;
		srcLocationId: string;
		quantity: string;
		fieldIdx?: number;
	}[];
	createdDate: string;
	arrivalDate?: string;
	doneDate?: string;
	note: string;
};

type MovingEditParams = {
	id: string
}

interface MovingEditProps {
	isStoring?: boolean;
}

const DEFAULT_WIDTH = {
	sku: 100,
	itemname: 400,
	srcLocation: 100,
	quantity: 80,
	stored: 320,
	operation: 100,
};

export const MovingEdit: React.FC<MovingEditProps> = (props) => {
	const params = useParams<MovingEditParams>();
	const isNew = params.id === 'new';

	const formMethods = useForm<FormValues>();
	const { control, getValues, watch, reset } = formMethods;
	const { fields, append } = useFieldArray({
		control,
		name: "skus",
	});
	const fildsGroupBySku = fields.reduce((data, field, index) => {
		if (!data[field.sku]) {
			data[field.sku] = [];
		}
		data[field.sku].push({
			...field,
			fieldIdx: index,
		});
		return data;
	}, {} as Record<string, FieldArrayWithId<FormValues, "skus", "id">[]>);

	const [, { getLocationGroups }] = useLocationGroup();
	const { getMoving, storedMoving, doneMoving } = useMovingMethods();
	const { getProducts } = useProductMethods();
	const { getBarcode } = useCSV();

	const [init, setInit] = useState<boolean>(false);
	const [width, setWidth] = useState(DEFAULT_WIDTH);
	const [locationGroups, setLocationGroups] = useState<LocationGroup[]>([]);
	const [storedListMap, setStoredListMap] = useState<Record<string, sasagase.MovingStored[]>>({});
	const [storingDialogSKU, setStoringDialogSKU] = useState<string | null>(null);
	const [productNameMap, setProductNameMap] = useState<Record<string, string>>({});

	// ロケーション一覧取得
	useEffect(() => {
		const load = async () => {
			const lgs = await getLocationGroups();
			setLocationGroups(lgs);
		};

		load();
	}, []);

	// ID変化時はページをリセットする
	useEffect(() => {
		if (!init) {
			return;
		}
		reset();
	}, [params.id]);

	// moving取得(新規作成時はダミーのmovingを設定)
	useEffect(() => {
		if (init) {
			return;
		}

		const movingLoad = async () => {
			const initValues = {
				// 新規作成時は内部的にuuidを生成しておく
				id: crypto.randomUUID(),
				srcLocationGroupId: null,
				destLocationGroupId: '',
				items: [],
				storedItems: [],
				number: '',
				state: 'moving',
				createdDate: '',
				note: '',
			};
			const moving = isNew ?
				sasagase.schemaMoving.parse(initValues) :
				sasagase.schemaMoving.parse(await getMoving(params.id ?? throwExp()));

			// フォームの形式に変換
			interface Items {
				sku: string;
				locs: {
					locId: number | null;
					quantity: number;
				}[];
				stored: sasagase.MovingStored[];
			}
			const skuItemMap: Record<string, Items> = {};
			for (const mvItem of moving.items) {
				const item = skuItemMap[mvItem.sku] ??= {
					sku: mvItem.sku,
					locs: [],
					stored: [],
				};
				item.locs.push({
					locId: mvItem.srcLocationId,
					quantity: mvItem.quantity,
				});
			}
			for (const stItem of moving.storedItems) {
				const item = skuItemMap[stItem.sku] ??= {
					sku: stItem.sku,
					// moving.itemsになくてmoving.storedItemsにだけあるSKUは初期入力欄を作成しておく
					locs: [{ locId: null, quantity: 0 }],
					stored: [],
				};
				item.stored.push(stItem);
			}

			const itemEntries = Object.entries(skuItemMap);
			itemEntries.sort(([skuA], [skuB]) => enCompare(skuA, skuB));

			const skus: FormValues['skus'] = [];
			const storedListMap: Record<string, sasagase.MovingStored[]> = {};
			for (const [sku, items] of itemEntries) {
				items.locs.forEach((loc) => {
					skus.push({
						sku,
						srcLocationId: String(loc.locId ?? ''),
						quantity: String(loc.quantity),
					});
				});
				storedListMap[sku] = items.stored;
			}

			// フォームの値を設定
			reset({
				id: moving.id,
				number: moving.number,
				srcLocationGroupId: moving.srcLocationGroupId?.toString() ?? '',
				destLocationGroupId: moving.destLocationGroupId?.toString() ?? '',
				state: moving.state,
				createdDate: moving.createdDate,
				arrivalDate: moving.arrivalDate ,
				doneDate: moving.doneDate,
				note: moving.note,
			});
			skus.forEach((sku => append({...sku})));
			setStoredListMap(storedListMap);
			setInit(true);
		};

		movingLoad();

	}, [init]);

	// 商品名取得のために商品情報を取得
	useEffect(() => {
		if (!fields || fields.length <= 0) {
			return;
		}

		const skus = [ ...new Set(fields.map(field => field.sku))];

		const productLoad = async () => {
			const products = await getProducts(skus);

			if (products) {
				// 商品情報から商品名マップを設定
				setProductNameMap((prev) => ({
					...prev,
					...Object.fromEntries(products.map((prod) => [prod.sku, prod.name])),
				}));
			}
		};

		productLoad();
	}, [fields]);

	const handleChangeWidth = (name: string) => (width: number) => {
		setWidth((curr) => ({
			...curr,
			[name]: width,
		}));
	};
	const handleClickStore = (sku: string) => () => {
		setStoringDialogSKU(sku);
	};
	const handleCloseStoringDialog = () => {
		setStoringDialogSKU(null);
	};
	const handleStore = async (storings: sasagase.StoreMovingItem[]) => {
		setStoringDialogSKU(null);

		const { id } = getValues();

		const result = await storedMoving(id, storings);
		const msg = result ? '入庫しました' : '入庫処理に失敗しました';
		alert(msg);

		// リロード
		setInit(false);
	};
	const handleClickDoneMoving = async () => {
		const { id } = getValues();

		const result = await doneMoving(id);
		const msg = result ? '移動伝票を完了しました' : '移動伝票の完了処理に失敗しました';
		alert(msg);

		// リロード
		setInit(false);
	};
	const fileSelect = async (): Promise<File> => {
		return new Promise((resolve, reject) => {
			const fileSelector = document.createElement("input");
			fileSelector.type = "file";
			fileSelector.accept = ".dat";
			fileSelector.addEventListener("change", function (e) {
				if (! (this.files && this.files.length)) {
					return reject(null);
				}
				resolve(this.files[0]);
			});
			fileSelector.click();
		});
	};
	const readTextFile = async (file: File): Promise<string> => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = e => {
				if (! (reader.result instanceof ArrayBuffer)) {
					return reject({ message: "ファイルの読込に失敗しました。", err: e });
				}
				const bin = new Uint8Array(reader.result);
				let enc = detectEncoding(bin, true) ?? 'SJIS';
				const utfbin = Encoding.convert(bin, "UNICODE", enc);
				if (utfbin[0] == 0xFEFF) {
					utfbin.shift();
					enc += " with BOM";
				}
				resolve(Encoding.codeToString(utfbin));
				console.log("Detected character encoding: " + enc);
			}
			reader.onerror = e => reject({ message: "ファイルの読込に失敗しました。", err: e });
			reader.readAsArrayBuffer(file);
		});
	};
	const parseDAT = (text: string): DatFile[] => {
		const lineReg = /^(?<date>[\d]{4}\/[\d]{2}\/[\d]{2})(?<location>[^ ]*)[ ]*(?<sku>[^ ]*)[ ]*(?<quantity>[\d]{4})(?<processDate>[\d]{4}\/[\d]{2}\/[\d]{2})(?<processTime>[\d]{2}:[\d]{2}:[\d]{2})$/;
		const records: DatFile[] = [];
		for (const line of text.split("\n")) {
			const matches = line.trim().match(new RegExp(lineReg));
			if (!matches || !matches.groups) {
				continue;
			}
			records.push({
				date: new Date(matches.groups.date),
				location: matches.groups.location,
				sku: matches.groups.sku,
				quantity: parseInt(matches.groups.quantity, 10),
				processDate: new Date(matches.groups.processDate + " " + matches.groups.processTime),
			});
		}
		return records;
	};
	const toStorings = async (records: DatFile[]): Promise<sasagase.StoreMovingItem[]> => {
		const barcode = await getBarcode();

		return records.map(rec => ({
			sku: rec.sku.replace(barcode.skuPrefix, ''),
			locationKey: rec.location,
			quantity: rec.quantity,
		}));
	};
	const handleClickUploadDat = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		const { id } = getValues();

		try {
			const file = await fileSelect();
			const text = await readTextFile(file);
			const records = parseDAT(text);
			const storings = await toStorings(records);
			const result = await storedMoving(id, storings);
			const msg = result ? 'DATファイルのアップロードが完了しました' : 'DATファイルのアップロードに失敗しました';
			alert(msg);

			// リロード
			setInit(false);
		} catch (err) {
			if (!err) {
				return;
			}
			console.log(err.err);
			alert(err.message);
		}
	};

	const locationNameMap = useLocationNameMap(locationGroups);

	const toDateString = (date?: string) => date && dayjs(date).format('YYYY/MM/DD');
	const toProductName = (sku: string) => productNameMap[sku] ?? `[${sku}]`;
	const toLocationName = (locId: number | null) => locationNameMap.get(locId) ?? `[${locId}]`;
	const toStateName = (state: string) => MOVING_STATE[state] ?? `[${state}]`;

	if (!locationGroups || !init) {
		return null;
	}

	const srcLocations = locationGroups.find((group) => String(group.id) === watch('srcLocationGroupId'))?.locations ?? [];
	const destLocations = locationGroups.find((group) => String(group.id) === watch('destLocationGroupId'))?.locations ?? [];
	const isStoring = props.isStoring;
	const isEditable = !isStoring && watch('state') !== 'done';
	const isStorable = isStoring && watch('state') !== 'done';

	return (
		<PageMain title={isNew ? '移動伝票 新規作成' : '移動伝票 編集'}>
			<PageBlock title="移動伝票">
				{storingDialogSKU &&
					<Overlay onClose={handleCloseStoringDialog}>
						<MovingStoringDialog
							name="MovingEditStoringDialog"
							sku={storingDialogSKU}
							destLocations={destLocations}
							onStore={handleStore}
							onClose={handleCloseStoringDialog}
						/>
					</Overlay>
				}
				<Form methods={formMethods}>
					<section>
						<div className="data-table">
							<table className="data-table">
								<tbody>
									<tr className="edit-sku-number_wrap">
										<th>伝票番号</th>
										<td>
											<Input type="text" name="number" disabled={!isEditable} />
											{isNew &&
												<p className="supplement">※空欄で自動生成</p>
											}
										</td>
									</tr>
									<tr className="edit-sku-number_wrap">
										<th>移動元グループ</th>
										<td>
											<Select name="srcLocationGroupId" disabled={!isEditable}>
												<option value="">入荷予定・未入荷数</option>
												{locationGroups.map((group) =>
													<option key={group.id} value={group.id}>{group.name}</option>
												)}
											</Select>
										</td>
									</tr>
									<tr className="edit-sku-number_wrap">
										<th>移動先グループ</th>
										<td>
											<Select name="destLocationGroupId" disabled={!isEditable}>
												<option value="" disabled={true}></option>
												{locationGroups.map((group) =>
													<option key={group.id} value={group.id}>{group.name}</option>
												)}
											</Select>
										</td>
									</tr>
								</tbody>
							</table>
							<table className="data-table">
								<tbody>
									<tr className="edit-sku-number_wrap">
										<th>ステータス</th>
										<td>{toStateName(watch('state'))}</td>
									</tr>
									<tr className="edit-sku-number_wrap">
										<th>作成日</th>
										<td>{toDateString(watch('createdDate'))}</td>
									</tr>
									<tr className="edit-sku-number_wrap">
										<th>到着予定日</th>
										<td><InputDate mode="date" name="arrivalDate" disabled={!isEditable} /></td>
									</tr>
									<tr className="edit-sku-number_wrap">
										<th>完了日</th>
										<td>{toDateString(watch('doneDate'))}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</section>
					<section>
						<h4>備考</h4>
						<Textarea name="note" rows={5} disabled={!isEditable} />
					</section>
					<section>
						<h4>移動商品</h4>
						<table className="edit-table">
							<thead>
								<tr>
									<ResizableTH width={DEFAULT_WIDTH.sku} name="sku">SKU</ResizableTH>
									<ResizableTH width={DEFAULT_WIDTH.itemname} name="itemname">商品名</ResizableTH>
									<ResizableTH width={DEFAULT_WIDTH.srcLocation} name="srcLocation" onChangeWidth={handleChangeWidth('srcLocation')}>移動元</ResizableTH>
									<ResizableTH width={DEFAULT_WIDTH.quantity} name="quantity"onChangeWidth={handleChangeWidth('quantity')}>数量</ResizableTH>
									<ResizableTH width={DEFAULT_WIDTH.stored} name="stored">入庫済</ResizableTH>
									<ResizableTH width={DEFAULT_WIDTH.operation} name="operation">操作</ResizableTH>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{Object.entries(fildsGroupBySku).map(([sku, fields], itemIdx) =>
									<tr key={itemIdx}>
										<td><span>{sku}</span></td>
										<td className="text-wrap"><span>{toProductName(sku)}</span></td>
										<td colSpan={2}>
											<table>
												<tbody>
													{fields.map((field, idx) =>
														<tr key={field.id}>
															<td width={width.srcLocation}>
																<Select name={`skus.${field.fieldIdx}.srcLocationId`} disabled={!isEditable}>
																	<option value="">未指定</option>
																	{srcLocations.map((loc) =>
																		<option key={loc.id} value={loc.id}>{loc.name}</option>
																	)}
																</Select>
															</td>
															<td width={width.quantity}>
																<Input type="text" name={`skus.${field.fieldIdx}.quantity`} className="right" disabled={!isEditable} />
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</td>
										<td>
											<table style={{ width: '100%' }}>
												<tbody>
													{storedListMap[sku]?.map((stored, idx) =>
														<tr key={idx}>
															<td><span>{toDateString(stored.storedDate)}</span></td>
															<td><span>{toLocationName(stored.srcLocationId)}</span></td>
															<td><span>→ {toLocationName(stored.destLocationId)}</span></td>
															<td><span>{stored.quantity}</span></td>
														</tr>
													)}
												</tbody>
											</table>
										</td>
										<td>
											{isStoring &&
												<button type="button" onClick={handleClickStore(sku)} disabled={!isStorable}>入庫</button>
											}
										</td>
										<td></td>
									</tr>
								)}
							</tbody>
						</table>
					</section>
				</Form>
			</PageBlock>
			<div className="submit-area">
				{isStoring && <>
					<button type="button" onClick={handleClickUploadDat} disabled={!isStorable}>DATファイルアップロード</button>
					{' '}
					<button type="button" className="register" onClick={handleClickDoneMoving} disabled={!isStorable}>移動完了</button>
				</>}
			</div>
		</PageMain>
	);
}
export default MovingEdit;