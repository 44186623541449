import { APIRequest } from '.';

export function getProductBySkus(skus: string[]): APIRequest {
	const params = new URLSearchParams({ search: '' });
	return {
		method: 'post',
		url: `/api/products?` + params.toString(),
		data: {
			conditions: [{
				type: 'in',
				item: 'sku',
				value: skus,
			}]
		}
	};
}