import { Readable } from "node:stream";

export function streamToBuffer(readable: Readable): Promise<Buffer> {
	return new Promise((resolve, reject) => {
		const chunks: Buffer[] = [];
		readable.on('data', (chunk) => {
			chunks.push(chunk);
		});
		readable.on('end', () => {
			resolve(Buffer.concat(chunks));
		});
		readable.on('error', (err) => {
			reject(err);
		});
	});
}
export default streamToBuffer;
