import * as React from 'react';
import { getInventoryQuantity } from "../api";
import { useAPI } from "../context";

export interface InventorySearchConditions {
	conditions: {
		type: string;
		item: string;
		value: string;
	}[];
	locationGroupId: number;
}

export interface ProductQuantity {
	/** SKU */
	sku: string;
	/** 商品名 */
	name: string;
	/** 商品属性値 */
	attributes: Record<string, unknown>;
	/** ステータス別数量
	 * location.tag.state: { [locationId]: { [tag]: { [state]: quantity }}}
	 */
	quantity: Record<string, Record<string, Record<string, number>>>;
	/** ロケーション別の出荷の引当て済み数
	 * { [locationId]: number }
	 */
	reservation: Record<string, number>;
}

export interface ProductLocationQuantity extends ProductQuantity {
	locationId: number;
	locationQuantity: number;
}

interface AggQuantityTarget {
	locationIds?: (number | null)[];
	tags?: string[];
	states?: string[];
}

/**
 * 在庫数の取得
 */
export function useInventory(conds?: InventorySearchConditions): ProductLocationQuantity[] | undefined {
	const callAPI = useAPI();

	const [products, setProducts] = React.useState<ProductLocationQuantity[] | undefined>(undefined);

	function aggQuantity(product: ProductQuantity, target: AggQuantityTarget): number | undefined {
		const locationQuantities = product.quantity ? [product.quantity] : [];
		const tagQuantities = pickProperties(locationQuantities, target.locationIds?.map((id) => id ?? ''));
		const stateQuantities = pickProperties(tagQuantities, target.tags);
		const quantities = pickProperties(stateQuantities, target.states);
		if (quantities.length <= 0) {
			return 0;
		}
		return quantities.reduce((acc, quantity) => acc + quantity);
	}
	function pickProperties<T>(objects: Record<PropertyKey, T>[], keys?: PropertyKey[]): T[] {
		return objects.flatMap((obj) => keys?.flatMap((key) => obj[key] ?? []) ?? Object.values(obj));
	}

	React.useEffect(() => {
		if (!conds) {
			setProducts(undefined);
			return;
		}

		const load = async () => {
			const result = await callAPI(getInventoryQuantity(conds));
			const flatProducts = result.data.flatMap((p: ProductQuantity) => {
				return Object.keys(p.quantity).map(locationId => ({
					...p,
					locationId: parseInt(locationId, 10),
					locationQuantity: aggQuantity(p, { locationIds: [parseInt(locationId, 10)], states: ['stocked'] }),
				}));
			});
			setProducts(flatProducts);
		};

		load();
	}, [conds]);

	return products;
}
export default useInventory;
