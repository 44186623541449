import * as React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import GlobalMenu from './GlobalMenu';
import LoginMenu from './LoginMenu';
import NaviMenu from './NaviMenu';

export const Header: React.FC = () => {

	return (
		<header id="main-header">
			<nav id="main-nav">
				<div className="nav1 flex-box between">
					<Link to="/" className="logo"></Link>
					<div className="flex-box end">
						<LoginMenu />
					</div>
				</div>
				<div className="nav2 flex-box">
					<GlobalMenu />
				</div>
			</nav>
			<Routes>
				<Route path="/inventory/*" element={<NaviMenu type="inventory" />} />
				<Route path="/shipping/*" element={<NaviMenu type="shipping" />} />
			</Routes>
		</header>
	);
};
export default Header;