export type PrefixedKeys<T, Prefix extends string> = {
	[K in keyof T as `${Prefix}${string & K}`]: T[K];
};

export function addPrefixToKeys<T extends object, Prefix extends string>(obj: T, prefix: Prefix): PrefixedKeys<T, Prefix> {
	return Object.fromEntries(
		Object.entries(obj).map(([key, val]) => [`${prefix}${key}`, val])
	) as PrefixedKeys<T, Prefix>;
}
export default addPrefixToKeys;
