import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLocationGroup, useLogin } from '../../../hooks';

export const LoginMenu: React.FC = () => {
	const [loginState, { logout }] = useLogin();
	const [locationGroup] = useLocationGroup();

	return (
		<ul className="login-menu">
			<li>
				出荷ロケーション：{locationGroup &&
					locationGroup.name
				}
			</li>
			<li>
				ユーザ名：{ loginState?.username }
			</li>
			<li>
				<Link to="/" onClick={ logout }>ログアウト</Link>
			</li>
		</ul>
	);
};
export default LoginMenu;