const collator = new Intl.Collator();

interface MapChainNode<T> {
	children: Map<string, MapChainNode<T>>;
	val: T | undefined;
}
interface MapChainLeaf<T> {
	val: T;
}

/**
 * オブジェクトによる複合キーSet
 * 
 * 通常のSetでは、値がオブジェクトの場合はインスタンスが一致するかどうかで同一性が判定されるが、
 * このSetではオブジェクトの各プロパティが同じかどうかで同一性を判定する。
 * プロパティの同一性は浅い比較で判定するので、プロパティが更にオブジェクトの場合はインスタンスの一致で判定する。
 * 
 * add時点でのプロパティを保持するので、add後にaddしたオブジェクトを変更した場合でも一致判定に影響しない。
 * また、`values`等で取得する値もadd時点でのプロパティを保持した浅いコピーを返す。
 */
export class PropertySet<T> extends Set<T> {
	static get [Symbol.species]() {
		return PropertySet;
	}

	#root: MapChainNode<T> = {
		children: new Map(),
		val: undefined,
	};

	#retrieveNode(val: T): MapChainLeaf<T> | null {
		if (typeof val != 'object' || val === null) {
			return null;
		}

		// プロパティ名でソートして、[k1, v1, k2, v2, ...]の形に整形する
		const sortedKV = Object.entries(val)
				.sort(([a], [b]) => collator.compare(a, b))
				.flat();
		let node = this.#root;
		for (const key of sortedKV) {
			let child = node.children.get(key);
			if (!child) {
				child = {
					children: new Map(),
					val: undefined,
				};
				node.children.set(key, child);
			}
			node = child;
		}
		// 初回はその値のシャローコピーを作成して保持する
		if (!node.val) {
			node.val = { ...val }; // TODO: 配列だと変なコピーになる
		}
		return node as MapChainLeaf<T>;
	}

	constructor(it?: Iterable<T> | null) {
		super();
		for (const val of it ?? []) {
			this.add(val);
		}
	}

	add(val: T): this {
		const node = this.#retrieveNode(val);
		if (!node) {
			return super.add(val);
		}
		return super.add(node.val);
	}

	delete(val: T): boolean {
		const node = this.#retrieveNode(val);
		if (!node) {
			return super.delete(val);
		}
		return super.delete(node.val);
	}

	has(val: T): boolean {
		const node = this.#retrieveNode(val);
		if (!node) {
			return super.has(val);
		}
		return super.has(node.val);
	}
}
export default PropertySet;
