import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface ErrorMessageProps {
	name: string;
	message?: string;
}
export const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
	const { formState: { errors } } = useFormContext();

	return <HookFormErrorMessage
		errors={errors}
		{...props}
		render={({message}) =>
			<p className='error'>{message}</p>
		}
	/>;
}
export default ErrorMessage;
