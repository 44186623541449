import { sasagase } from '@sasagase/types';
import { APIRequest } from '.';

export function getMoving(id: string): APIRequest {
	return {
		method: 'get',
		url: `/api/moving/${id}`,
	};
}

export function getMovings(cond: Record<string, string>): APIRequest {
	const params = new URLSearchParams(cond);
	return {
		method: 'get',
		url: `/api/moving?` + params.toString(),
	};
}

export function storedMoving(id: string, storing: sasagase.StoreMovingItem[]): APIRequest {
	return {
		method: 'post',
		url: `/api/moving/${id}/stored`,
		data: storing
	};
}

export function doneMoving(id: string): APIRequest {
	return {
		method: 'put',
		url: `/api/moving/${id}/done`,
	};
}