import * as React from 'react';
import { getInventoryStates } from "../api";
import { useAPI } from "../context";
import { InventoryState } from '../types';

/**
 * 在庫のステート一覧取得
 */
export function useInventoryState(): InventoryState[] | undefined {
	const callAPI = useAPI();

	const [inventoryStates, setInventoryStates] = React.useState<InventoryState[] | undefined>(undefined);

	React.useEffect(() => {
		const load = async () => {
			const result = await callAPI(getInventoryStates());
			setInventoryStates(result.data);
		};

		load();
	}, []);

	return inventoryStates;
}
export default useInventoryState;
