type Strip<T> = {
	[K in keyof T]: T[K] extends undefined ? never : T[K];
};

/**
 * 元のオブジェクトから`undefined`が設定されているプロパティを削除したオブジェクトを生成して返す。
 */
export function strip<T extends object>(obj: T): Strip<T> {
	return Object.fromEntries(
		Object.entries(obj)
			.filter(([_, val]) => val !== undefined)
	) as Strip<T>;
}
export default strip;
