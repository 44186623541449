import { adjustAddress } from '@sasagase/adjust-address';
import stringify from "csv-stringify";
import Encoding from "encoding-japanese";
import iconv from 'iconv-lite';
import { useCSV } from '.';
import { Barcode, InvoiceData, InvoiceDataMap, Shipping } from '../types';
import { NameAddress } from './useCSV';

interface UseMethods {
	getOptions: () => stringify.Options;
	getRecords: (shippings: Shipping[], invoiceDataMap: InvoiceDataMap, barcode: Barcode) => Record<string, string|number>[];
}

export const useYamatoCSV = (): UseMethods => {

	const {
		parseDate,
		parseTimezone,
		parseDeliveryDate,
	} = useCSV();

	const getOptions = (): stringify.Options => {
		return {
			columns: [
				"お客様管理番号", "送り状種類", "クール区分", "伝票番号", "出荷予定日", "お届け予定日", "配達時間帯",
				"お届け先コード", "お届け先電話番号", "お届け先電話番号枝番", "お届け先郵便番号", "お届け先住所", "お届け先アパートマンション名",
				"お届け先会社・部門１", "お届け先会社・部門２", "お届け先名", "お届け先名(カナ)", "敬称",
				"ご依頼主コード", "ご依頼主電話番号"," ご依頼主電話番号枝番", "ご依頼主郵便番号", "ご依頼主住所", "ご依頼主アパートマンション",
				"ご依頼主名", "ご依頼主名(カナ)", "品名コード１", "品名１", "品名コード２", "品名２", "荷扱い１", "荷扱い２", "記事",
				"コレクト代金引換額（税込)", "内消費税額等", "止置き", "営業所コード", "発行枚数", "個数口表示フラグ",
				"請求先顧客コード", "請求先分類コード", "運賃管理番号"
			],
			header: true,
			quoted: true,
			quoted_empty: true,
		};
	};

	const getRecords = (shippings: Shipping[], invoiceDataMap: InvoiceDataMap, barcode: Barcode) => {
		/** 送り状種類 0:発払い, 2:コレクト, 3:DM便, 4:タイム, 5:着払い, 7:ネコポス, 8:宅急便コンパクト, 9:宅急便コンパクトコレクト */
		const SHIPPING_METHOD: Record<string, string|Record<string, string>> = {
			"ヤマト運輸": "0",
			"通常配送 （北海道・沖縄のお届けは、一部送料が変動します。）": "0",
			"宅配便": "0",
			"宅急便": "0",
			"ヤマト": "0",
			"ヤマト運輸(送料無料)": "0",
			"代金引換": "2",
			"コレクト": "2",
			"メール便": "3",
			"DM便": "3",
			"ネコポス": "7",
			"追跡可能メール便": "7",
			"小型宅配便": "8",	// 宅急便コンパクト
			"ネコポス便送料無料": "7",
			"ネコポス便": "7",
			"コンパクト": { normal: "8", cod: "9" },
		};
		/** 代引き未指定の場合の送り状種類 */
		const DEFAULT_COD_METHOD = "2";
		/** CSVに出力しない配送方法のリスト */
		const IGNORE_METHOD = ["大型宅配便", "大型1", "大型2", "大型メーカー直送便", "国際配送"];

		const records = shippings.map(shipping => {
			if (IGNORE_METHOD.includes(shipping.method)) {
				return null;
			}

			let type = SHIPPING_METHOD[shipping.method] || shipping.method;
			if (shipping.cod) {
				type = typeof type == 'object' && type.cod || DEFAULT_COD_METHOD;
			} else {
				type = typeof type == 'object' && type.normal || type;
			}
			const cod = shipping.cod ? shipping.cod : "";

			const dest = shipping.dest;

			const parsedDelivery = parseDeliveryDate(shipping.delivery_date);
			const pDate = parseDate(parsedDelivery.date);
			const pTime = parseTimezone(parsedDelivery.timezone);
			const pShip = parseDate(parsedDelivery.shipping || new Date());

			const deliveryDate = pDate ? `${pDate.year}/${pDate.mon}/${pDate.day}` : parsedDelivery.date;
			const deliveryTimezone = pTime ? `${pTime.begin}${pTime.end}` : parsedDelivery.timezone;
			const shippingDate = pShip ? `${pShip.year}/${pShip.mon}/${pShip.day}` : parsedDelivery.shipping;

			const idtext = shipping.orders.map(order => order.idtext).join(", ");
			const method = shipping.orders.map(order => order.method).join(", ");

			const [address, subaddress, company, section] = adjustAddressGeneral(dest, true);

			const invoiceData = invoiceDataMap[shipping.orders[0].method];

			const client: NameAddress = getClientAddress(shipping, invoiceData);
			const [clientAddress, clientSubaddress] = adjustAddressGeneral(client);

			return {
				"お客様管理番号": `${shipping.id} ${method} ${idtext}`,
				"送り状種類": type,
				"出荷予定日": shippingDate,
				"お届け予定日": deliveryDate,
				"配達時間帯": deliveryTimezone,
				"お届け先電話番号": dest.phone,
				"お届け先郵便番号": dest.zip,
				"お届け先住所": address,
				"お届け先アパートマンション名": subaddress,
				"お届け先会社・部門１": company,
				"お届け先会社・部門２": section,
				"お届け先名": dest.name,
				"お届け先名(カナ)": toHankaku(dest.kana),
				"ご依頼主電話番号": client.phone,
				"ご依頼主郵便番号": client.zip,
				"ご依頼主住所": clientAddress,
				"ご依頼主アパートマンション": clientSubaddress,
				"ご依頼主名": client.name,
				"ご依頼主名(カナ)": toHankaku(client.kana),
				"コレクト代金引換額（税込)": cod,
				"内消費税額等": dest.codtax,
				"品名１": invoiceData.shipping.contents,
				"荷扱い１": 'ワレ物注意',
				"請求先顧客コード": invoiceData.shipping.payerCode,
				"運賃管理番号": invoiceData.shipping.feeCode,
			};
		});
		return records.filter(Boolean) as Record<string, string|number>[];
	};

	const getClientAddress = (shipping: Shipping, invoiceData: InvoiceData) => {
		const orderers: NameAddress[] = shipping.orders.map(order => {
			// ordererの各プロパティは手動登録の注文だと配列のことがあるので変換する
			const ret: Record<string, string> = {};
			for (const [key, val] of Object.entries(order.orderer)) {
				ret[key] = (val || "").toString();
			}
			return ret;
		});

		// 注文者と送付先の名前と住所が同じ場合に送付元の情報を「初期値(ぷりふあ人形)」に変更
		// 注文者の住所情報がない場合は初期値の住所を使用して、名前だけ注文者を使用する =>「ぷりふあ人形 XXX 様御依頼分」
		//   注文者の名前を含め全角16文字/半角32文字以上になった場合、設定しているアパートマンション名が空なら
		//   ご依頼主アパートマンション名に「ぷりふあ人形」
		//   ご依頼主名に「XXX 様御依頼分」を適用する
		// それ以外の場合は注文者の情報を使用する
		const client: NameAddress|undefined = orderers.find(orderer => {
			const ordererAddress = adjustAddressGeneral(orderer).join('');
			const shippingAddress = adjustAddressGeneral(shipping.dest).join('');
			return !(
				spaceIgnoreEqual(orderer.name, shipping.dest.name) &&
				spaceIgnoreEqual(ordererAddress, shippingAddress)
			);
		});
		if (!client) {
			return invoiceData.client;
		} else if (isEmptyAddress(client)) {
			let subaddress = invoiceData.client.subaddress;
			let name;
			if (client.name) {
				name = [invoiceData.names.before, client.name.replace(/\s+/g, ' '), invoiceData.names.after].join('');
				if (iconv.encode(name, 'shift_jis').length > 32 && subaddress === '') {
					subaddress = invoiceData.names.before;
					name = [client.name.replace(/\s+/g, ' '), invoiceData.names.after].join('');
				}
			}
			return {
				...invoiceData.client,
				subaddress: subaddress,
				name: name || invoiceData.client.name,
			};
		} else {
			return client;
		}
	};

	const spaceIgnoreEqual = (a = "", b = ""): boolean => {
		return a.replace(/\s/g, "") == b.replace(/\s/g, "");
	};

	const isEmptyAddress = (addr: NameAddress): boolean => {
		return [addr.country, addr.pref, addr.city, addr.address, addr.subaddress].join('') == '';
	};

	/**
	 * 住所と建物名等の区切り位置を調整する。
	 *
	 * @param country 国名
	 * @param pref 都道府県(4文字)
	 * @param city 市区郡町村(12文字)
	 * @param address 字・番地(16文字)
	 * @param subaddress 建物名等(16文字)
	 * @param company 会社名(25文字)
	 * @param section 部門名(25文字)
	 * @param withCompany 戻り値に会社名・部門名を含めるか
	 * @returns [住所(頭から16文字), 住所(次の16文字), 住所(ここまでに入り切らなかった16文字), 会社名(25文字 or 0文字), 部門名(25文字 or 0文字)]
	 */
	const adjustAddressGeneral = ({ country = "", pref = "", city = "", address = "", subaddress = "", company = "", section = "" }, withCompany = false) => {
		// wowmaの住所情報が`[都道府県] [それ以降]`という1つの文字列なので、ヤマト用に都道府県名の後のスペースを消す必要がある
		if (!pref && city) {
			[pref, city] = splitPref(city);
		} else if (!pref && address) {
			[pref, address] = splitPref(address);
		}

		// useHale=falseの場合、全角変換したもので調整する
		const values = [ address, subaddress, company, section ];
		const limits = [
			(pref ? 0 : 8) + (city ? 0 : 24) + 32, // `pref`, `city`が空の場合、それらの値は`address`に含まれているはずなので、その分文字数を増やす
			32,
			withCompany ? 50 : 0,
			withCompany ? 50 : 0,
		];
		const [ _address, _subaddress, _company, _section ] = adjustAddress(values, limits, 'shift_jis');
		return [country + pref + city + _address, _subaddress, _company, _section];
	};

	/**
	 * 文字列を都道府県名とそれ以降に分割する
	 * 都道府県名がなければ空文字列
	 * @returns [都道府県名, それ以降]
	 */
	const splitPref = (addr: string): [string, string] => {
		const parts = /\s*(.{2,3}[都道府県])\s*(.*)\s*$/.exec(addr);
		if (!parts) {
			return ['', addr];
		}
		return [parts[1], parts[2]];
	};

	/**
	 * ASCII系で半角表現可能な全角文字を全て半角文字に置き換える
	 *
	 * @private
	 * @param str
	 * @returns
	 */
	const toHankaku = (str = ""): string => {
		str = Encoding.toKatakanaCase(str); // 全角ひらがな to 全角カタカナ
		str = Encoding.toHankakuCase(str); // 全角英数字記号 to 半角
		str = Encoding.toHankakuSpace(str); // 全角スペース to 半角
		str = Encoding.toHankanaCase(str); // 全角カタカナ to 半角
		return str;
	};

	return {
		getOptions,
		getRecords,
	};
};