import { parse as CSVparse } from "csv-parse";
import * as Encoding from "encoding-japanese";
import * as React from 'react';
import { useCSV, useLogin } from '../../../hooks';
import detectEncoding from "../../../lib/detect-encoding";
import { Barcode, ShippingControl } from '../../../types';

export const ShippingImport: React.FC = () => {

	const [loginState] = useLogin();
	const { getBarcode, postShippingCompleted } = useCSV();

	const fileSelect = async (): Promise<File> => {
		return new Promise((resolve, reject) => {
			const fileSelector = document.createElement("input");
			fileSelector.type = "file";
			fileSelector.addEventListener("change", function (e) {
				if (! (this.files && this.files.length)) {
					return reject(null);
				}
				resolve(this.files[0]);
			});
			fileSelector.click();
		});
	};

	const readTextFile = async (file: File): Promise<string> => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = e => {
				if (! (reader.result instanceof ArrayBuffer)) {
					return reject({ message: "ファイルの読込に失敗しました。", err: e });
				}
				const bin = new Uint8Array(reader.result);
				let enc = detectEncoding(bin, true) ?? 'SJIS';
				const utfbin = Encoding.convert(bin, "UNICODE", enc);
				if (utfbin[0] == 0xFEFF) {
					utfbin.shift();
					enc += " with BOM";
				}
				resolve(Encoding.codeToString(utfbin));
				console.log("Detected character encoding: " + enc);
			}
			reader.onerror = e => reject({ message: "ファイルの読込に失敗しました。", err: e });
			reader.readAsArrayBuffer(file);
		});
	};

	const parseCSV = async (text: string): Promise<any | undefined> => {
		return new Promise(function (resolve, reject) {
			const opt = {
				columns: function (columns: string[]) {
					return columns.map( column => {
						if (column === "お客様管理番号") {	// ヤマト,佐川:e飛伝Ⅲ
							return 'controlNumber';
						} else if (column === "お客様管理ナンバー") {	// 佐川:e飛伝Ⅱ
							return 'controlNumber';
						} else if (column === "伝票番号") {	// ヤマト,佐川:e飛伝Ⅱ,Ⅲ
							return 'tracking';
						} else if (column === "お問合せ送り状№") {	// 佐川:e飛伝Ⅱ
							return 'tracking';
						} else if (column === "お問い合せ送り状No.") {	// 佐川:e飛伝Ⅲ
							return 'tracking';
						} else if (column === "出荷予定日") {	// ヤマト
							return 'shippingDate';
						} else if (column === "送り状種類") {	// ヤマト
							return 'deliveryService';
						} else if (column === "削除区分") {	// 佐川:e飛伝Ⅱ,Ⅲ
							return 'deleteFlg';
						} else {
							return column;
						}
					});
				},
			};
			CSVparse(text, opt, function (err, output) {
				if (err) {
					const message = [
						'CSV解析に失敗しました。',
						err instanceof Error ? err.message : String(err),
					];
					return reject({ message: message.join('\n'), err });
				}
				resolve(output);
			});
		});
	};

	const toShippings = async (records: any) => {
		const barcode = await getBarcode();

		return new Promise<ShippingControl[]>(function (resolve, reject) {
			try {
				const shippings = new Map();
				for (const record of records) {
					if (! record.controlNumber || ! record.tracking) {
						continue;
					}
					const shipping = toShipping(record, barcode);
					if (!shipping) {
						continue;
					}
					shippings.set(shipping.id, shipping);
				}
				if (! shippings.size) {
					reject({ message: "取込可能な送り状番号のレコードがありません。", err: "No imported records." })
				}
				resolve([...shippings.values()]);
			} catch (err) {
				reject({ message: "レコードデータの解析に失敗しました。", err });
			}
		});
	};

	const toShipping = (record: any, barcode: Barcode): ShippingControl | null => {
		const { controlNumber, tracking, shippingDate, deliveryService, deleteFlg } = record;
		if (loginState?.permission.deliveryCompany === 'yamato') {
			// ヤマト運輸
			const [ id, method, idtext ] = controlNumber.split(" ");
			const deliveryServiceName: Record<string, string> = {
				'0': '宅急便',
				'2': 'コレクト',
				'3': 'DM便',
				'7': 'ネコポス',
				'8': '宅急便コンパクト',
				'9': '宅急便コンパクトコレクト'
			};
			return {
				id: parseInt(id, 10),
				method,
				idtext,
				tracking,
				shippingDate: shippingDate ? new Date(shippingDate).getTime() : undefined,
				deliveryCompany: 'ヤマト運輸',
				deliveryService: deliveryServiceName[deliveryService],
			};
		} else {
			// 佐川急便
			if (deleteFlg !== '0') {	// 0以外を無視する（1:削除,2:キャンセル？）
				return null;
			}
			if (controlNumber.trim().indexOf(barcode.shippingPrefix) === -1) {
				return null;
			}
			const id = controlNumber.trim().replace(barcode.shippingPrefix, '');
			return {
				id: parseInt(id, 10),
				tracking,
				shippingDate: shippingDate ? new Date(shippingDate).getTime() : undefined,
				deliveryCompany: '佐川急便',
				deliveryService: '飛脚宅配便',	// 固定
			};
		}
	};

	const handleClickUpload = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		if (!loginState) {
			return;
		}

		try {
			const file = await fileSelect();
			const text = await readTextFile(file);
			const records = await parseCSV(text);
			const shippings = await toShippings(records);
			const result = await postShippingCompleted(shippings, loginState.permission.locationGroupId);
			const { success, shipped, invalid } = result;

			const msg: string[] = [];
			if (invalid > 0) {
				msg.push('送り状番号のアップロードに失敗しました');
				msg.push(`異なる出荷元や出荷ステータスが出荷作業中以外の情報が含まれています（${invalid} 件）`);
			} else if (success >= 0) {
				msg.push(`送り状番号のアップロードに成功しました`);
				msg.push(`新規追加： ${success} 件`)
				if (shipped > 0) {
					msg.push(`出荷済みのため追加無し： ${shipped} 件`);
				}
			}
			alert(msg.join("\n"));

		} catch (err) {
			if (!err) {
				return;
			}
			console.log(err.err);
			alert(err.message);
		}
	};

	return (
		<a href="" onClick={handleClickUpload}>送り状番号CSVアップロード</a>
	);
};
