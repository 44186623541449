import ja from 'date-fns/locale/ja';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('ja', ja);

interface TimePickerProps {
	onChange: (...event: any[]) => void;
	value: any;
	popperClassName?: string;
	disabled?: boolean;
}
export const TimePicker: React.FC<TimePickerProps> = (props) => {
	return (
		<ReactDatePicker
			popperClassName={props.popperClassName}
			locale="ja"
			onChange={props.onChange}
			selected={props.value}
			showTimeSelect
			showTimeSelectOnly
			timeIntervals={10}
			timeCaption="時間"
			dateFormat="HH:mm"
			timeFormat="HH:mm"
			disabled={props.disabled} />
	);
}
export default TimePicker;
