import * as React from 'react';

interface OverlayProps {
	onClose?: () => void;
	children?: React.ReactNode;
}

export const Overlay: React.FC<OverlayProps> = (props) => {
	const handleClickOverlay = () => {
		props.onClose?.();
	};
	const handleClickModal = (e: React.MouseEvent) => {
		// overlayにclickイベントが伝わらないようにする
		e.stopPropagation();
	};

	return (
		<div id="overlay" onClick={handleClickOverlay}>
			<div className="modalbox flex-box" onClick={handleClickModal}>
				{props.children}
			</div>
		</div>
	);
}
export default Overlay;