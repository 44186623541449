import * as React from 'react';
import { ProductLocationQuantity, useCSV } from '../../../hooks';
import { InventoryState, LocationGroup } from '../../../types';
import { IPPSelector } from '../../organisms/IPPSelector';
import { Pagination } from '../../organisms/Pagination';
import { ResizableTH } from '../../organisms/ResizableTH';

interface InventoryListTableProps {
	products?: ProductLocationQuantity[];
	locationGroupId: number;
	locationGroups: LocationGroup[];
	inventoryStates: InventoryState[];
}

export const InventoryListTable: React.FC<InventoryListTableProps> = (props) => {
	const { products, locationGroupId, locationGroups } = props;

	const [page, setPage] = React.useState<number>(0);
	const [IPP, setIPP] = React.useState<number|undefined>(100);
	const [skuPrefix, setSkuPrefix] = React.useState<string>('');

	const { getBarcode } = useCSV();

	React.useEffect(() => {
		const load = async () => {
			const barcode = await getBarcode();
			setSkuPrefix(barcode.skuPrefix);
		};
		load();
	}, []);

	if (!products) {
		return null;
	}

	const itemPerPage = IPP || products && products.length;
	const productsPage = products?.slice(page * itemPerPage, (page + 1) * itemPerPage);

	const locationGroup = locationGroups.find(lg => lg.id === locationGroupId);

	const toLocString = (locId: number) => locationGroup?.locations.find(l => l.id === locId)?.name ?? locId;

	return (
		<>
			{ "検索結果 " + products.length + "件 " }
			<div style={{float:"right"}}>
				<div className="ipp-selector">1ページの表示件数<IPPSelector ipp={ itemPerPage } setIPP={setIPP}/></div>
			</div>
			<div className="edit-table-box">
				<table className="edit-table">
					<thead>
						<tr>
							<ResizableTH width={ 120 } name="sku">SKU</ResizableTH>
							<ResizableTH width={ 550 } name="name">商品名</ResizableTH>
							<ResizableTH width={ 150 } name="location">ロケーション</ResizableTH>
							<ResizableTH width={ 80 } name="quantity">在庫数</ResizableTH>
							<ResizableTH width={ 80 } name="reserved">引当済み</ResizableTH>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{productsPage?.map(product =>
							<tr key={`${product.sku}-${product.locationId}`}>
								<td><span>{skuPrefix}{product.sku}</span></td>
								<td><span>{product.name}</span></td>
								<td><span>{toLocString(product.locationId)}</span></td>
								<td style={{ textAlign: "right" }}><span>{product.locationQuantity}</span></td>
								<td style={{ textAlign: "right" }}><span>{product.reservation[product.locationId] ?? ''}</span></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<Pagination now={ page } maxPage={ Math.ceil(products.length / itemPerPage) - 1 } setPage={setPage} />
		</>
	);
}
export default InventoryListTable;