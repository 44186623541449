import * as React from 'react';
import { useForm } from 'react-hook-form';
import { InventorySearchConditions, useInventory, useInventoryState, useLocationGroup } from '../../../hooks';
import { LocationGroup } from '../../../types';
import { Button } from '../../atoms/Button';
import { PageBlock } from '../../molecules/PageBlock';
import { PageMain } from '../../molecules/PageMain';
import { Form, Input } from '../../organisms/Form';
import { InventoryListTable } from './InventoryListTable';

const initValues = {
	sku: '',
	productName: '',
};
type FormValues = typeof initValues;

export const Inventory: React.FC = () => {
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const { getValues, reset } = formMethods;
	const [locationGroup, { getLocationGroups }] = useLocationGroup();

	const [locationGroups, setLocationGroups] = React.useState<LocationGroup[]>([]);
	const [inventoryConds, setInventoryConds] = React.useState<InventorySearchConditions|undefined>(undefined);

	const inventoryStates = useInventoryState();
	const products = useInventory(inventoryConds);

	React.useEffect(() => {
		const load = async () => {
			const lgs = await getLocationGroups();
			setLocationGroups(lgs);
		};

		load();
	}, []);

	const handleClickSearch = () => {
		if (!locationGroup) {
			return;
		}

		const { sku, productName } = getValues();
		const conditions = [
			{ type: "backward", item: "sku", value: sku.trim() },
			{ type: "ssv", item: "any", value: productName.trim() },
		].filter( condition => condition.value );

		setInventoryConds({
			conditions,
			locationGroupId: locationGroup.id,
		});
	};

	const handleClickReset = () => {
		setInventoryConds(undefined);
		reset(initValues);
	};

	if (!locationGroup || !locationGroups || !inventoryStates) {
		return null;
	}

	return (
		<PageMain title="在庫管理">
			<PageBlock>
				<section>
					<Form methods={formMethods} className="sku-search-form formbox">
						<div className="flex-box wrap inputArea">
							<div className="item-number size10rem">
								<Input placeholder="SKU(後方一致)" name="sku" />
							</div>
							<div className="item-name size25rem">
								<Input placeholder="属性値複合検索(スペース区切りAND検索)" name="productName" />
							</div>
						</div>
						<div className="flex-box submitArea">
							<Button onClick={handleClickSearch}>検索する</Button>
							<Button onClick={handleClickReset}>リセットする</Button>
						</div>
					</Form>
				</section>
			</PageBlock>
			<PageBlock title="在庫数一覧">
				<section>
					<InventoryListTable
						products={products}
						locationGroupId={locationGroup.id}
						locationGroups={locationGroups}
						inventoryStates={inventoryStates} />
				</section>
			</PageBlock>
		</PageMain>
	);
}
export default Inventory;