import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './component/App';

function getRoot(): Root {
	const root = document.getElementById('root');
	if (root) {
		return createRoot(root);
	}

	const div = document.createElement('div');
	div.id = 'root';
	document.body.appendChild(div);
	return createRoot(div);
}

// 環境変数をbodyのクラスに定義
document.body.classList.add(process.env.NODE_ENV ?? 'development');

window.addEventListener('load', () => {
	const root = getRoot();
	root.render(
			<BrowserRouter>
				<App />
			</BrowserRouter>
		);
});
