import * as React from 'react';

const LONG_TOUCH_MS = 500;

interface ResizableTHProps extends React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> {
	name: string;
	width: number;
	hide?: boolean;
	onChangeWidth?: (width: number) => void;
}

export const ResizableTH: React.FC<ResizableTHProps> = (props) => {
	const { children, width: defaultWidth, name, hide, onChangeWidth, ...thProps } = props;
	const [width, setWidth] = React.useState<number>(defaultWidth);
	const [isDragging, setIsDragging] = React.useState<boolean>(false);

	const handleRef = React.useRef<HTMLDivElement | null>(null);
	const timerHandleRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
	const prevPageXRef = React.useRef<null | number>(null);

	React.useEffect(() => {
		onChangeWidth?.(width);
	}, [width]);

	const mouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		// 左ボタンのみ動作する
		if (e.button != 0) {
			return;
		}
		setIsDragging(true);
	};
	const mouseUp = (e: MouseEvent) => {
		// 左ボタンのみ動作する
		if (e.button != 0) {
			return;
		}
		setIsDragging(false);
	};
	const mouseMove = (e: MouseEvent) => {
		setWidth((currWidth) => currWidth + e.movementX);
	};

	React.useEffect(() => {
		return () => {
			setWidth(defaultWidth);
			if (timerHandleRef.current) {
				clearTimeout(timerHandleRef.current);
			}
		};
	}, []);

	React.useEffect(() => {
		if (!isDragging) {
			return;
		}
		window.addEventListener("mouseup", mouseUp);
		window.addEventListener("mousemove", mouseMove);
		document.body.style.cursor = 'col-resize';
		return () => {
			window.removeEventListener("mouseup", mouseUp);
			window.removeEventListener("mousemove", mouseMove);
			document.body.style.cursor = '';
		};
	}, [isDragging]);

	const handleTouchStart: React.TouchEventHandler = (e) => {
		timerHandleRef.current = setTimeout(() => {
			timerHandleRef.current = null;
			setIsDragging(true);
		}, LONG_TOUCH_MS);
		prevPageXRef.current = e.touches[0]?.pageX ?? null;
	};
	const handleTouchEnd: React.TouchEventHandler = () => {
		if (timerHandleRef.current) {
			clearTimeout(timerHandleRef.current);
		}
		timerHandleRef.current = null;
		setIsDragging(false);
	};

	React.useEffect(() => {
		if (!handleRef.current) {
			return;
		}

		const handleTouchMove = (e: TouchEvent) => {
			// 長押し判定前に動かした場合はスクロールさせる
			if (!isDragging || timerHandleRef.current) {
				if (timerHandleRef.current) {
					clearTimeout(timerHandleRef.current);
					timerHandleRef.current = null;
				}
				return;
			}

			// 他の要因でスクロール中などの場合はpreventDefaultできないので処理しない
			if (!e.cancelable) {
				return;
			}
			e.preventDefault();

			const prev = prevPageXRef.current;
			const curr = e.touches[0]?.pageX ?? null;
			if (prev !== null && curr !== null) {
				setWidth((currWidth) => currWidth + curr - prev);
			}
			prevPageXRef.current = curr;
		};

		handleRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });
		return () => {
			handleRef.current?.removeEventListener('touchmove', handleTouchMove);
		};
	}, [isDragging, handleRef.current]);

	// React.useEffect(() => {
	// 	if (!isDragging && width != values[name]) {
	// 		setValuesDispatcher({ [name]: width });
	// 	}
	// }, [isDragging, width]);

	const style = {
		...props.style,
		width,
	};
	if (hide) {
		return null;
	}
	return (
		<th {...thProps} style={style}>
			{children}
			<div
				className="resizable-handle"
				ref={handleRef} // touchmoveのイベントリスナをpassive: falseにしたいのでref経由で登録する
				onMouseDown={mouseDown}
				onTouchStart={handleTouchStart}
				onTouchEnd={handleTouchEnd}
				onTouchCancel={handleTouchEnd}
				onContextMenu={(e) => e.preventDefault()} // タッチパネルで長押し時にメニューがでないように
			/>
		</th>
	);
};