import { sasagase } from "@sasagase/types";
import * as React from 'react';
import { getMovings } from "../api";
import { useAPI } from "../context";

export interface MovingConditions {
	state?: string;
	destLocationGroupId?: number;
}

/**
 * 移動伝票のリストを指定した条件で取得する
 */
export function useMovings(conds?: MovingConditions): sasagase.Moving[] | undefined {
	const callAPI = useAPI();

	const [movings, setMovings] = React.useState<sasagase.Moving[] | undefined>(undefined);

	React.useEffect(() => {
		if (!conds) {
			return;
		}

		const load = async () => {
			const params = Object.fromEntries(Object.entries({...conds}).map(([k,v]) => [k, String(v)]));
			const result = await callAPI(getMovings(params));
			setMovings(result.data);
		};

		load();
	}, [conds]);

	return movings;
}
export default useMovings;
