import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface InputRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
	children?: React.ReactNode;
	rules?: Record<string, unknown>;
	className?: string;
}
export const InputRadio: React.FC<InputRadioProps> = (props) => {
	const { register } = useFormContext();

	const { children, name, rules, onChange, ...inputProps } = props;

	if (!children) {
		return <input type='radio' {...register(name ?? '', rules ? { ...rules, onChange } : { onChange })} {...inputProps} />;
	}

	return (
		<label>
			<input type='radio' {...register(name ?? '', rules ? { ...rules, onChange } : { onChange })} {...inputProps} />
			<span>{children}</span>
		</label>
	);
}
export default InputRadio;
