export interface WaitingOption<T> {
	/** タイムアウト時間(ms) 未指定でタイムアウトしない */
	timeout?: number;
	/**
	 * タイムアウト時のコールバック
	 * 指定した場合はコールバック呼出しの戻り値でPromiseをresolveする。
	 * コールバック内でthrowしたら、その値でrejectする。
	 * 未指定の場合はErrorでrejectする。
	 */
	onTimeout?: () => T | Promise<T>;
	/** timeout指定時のタイマーで、Node.jsの自動終了をブロックするか(default true) */
	ref?: boolean;
}

/**
 * 通常のコールバックによる方法以外でPromiseを解決したい
 * 
 * 通常のPromiseは以下のようにして使うが、
 * ```
 * new Promise((resolve, reject) => { ... });
 * ```
 * コンストラクタの関数内で設定するコールバック以外でresolveしようと思ったら、resolve関数をどこかに保持しておく必要がある。
 * 
 * なのでもう、resolve, rejectと、それによって解決されるPromiseをセットにしたオブジェクトを作ってしまう。
 */
export class Waiting<T = void> {
	private _resolve!: (val: T | Promise<T>) => void;
	private _reject!: (reason?: unknown) => void;
	private _timer?: NodeJS.Timeout;

	/** WaitingのPromise */
	readonly promise: Promise<T>;

	constructor(opt: WaitingOption<T> = {}) {
		this.promise = new Promise((resolve, reject) => {
			this._resolve = resolve;
			this._reject = reject;
		});
		if (opt.timeout !== undefined) {
			this._timer = setTimeout(() => {
				try {
					if (!opt.onTimeout) {
						throw new Error(`Timeout(${opt.timeout}) waiting`);
					}
					this._resolve(opt.onTimeout());
				} catch (err: unknown) {
					this._reject(err);
				}
			}, opt.timeout);
			if (opt.ref === false) {
				this._timer = this._timer.unref();
			}
		}
	}

	/** Waitingをresolveさせる */
	resolve(val: T | Promise<T>): void {
		clearTimeout(this._timer);
		this._resolve(val);
	}

	/** Waitingをrejectさせる */
	reject(reason?: unknown): void {
		clearTimeout(this._timer);
		this._reject(reason);
	}
}
export default Waiting;
