import React, { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../atoms/Button';
import { Form } from '../../organisms/Form';
import Select from '../../organisms/Form/Select';
import useShippingState from '../../../hooks/useShippingState';
import { PromiseWrapper } from '../../../hooks';
import { Shipping } from '../../../types';

interface ShippingCancelProps {
	shippings: PromiseWrapper<Shipping[]>;
	onDone: () => void;
	onClose: () => void;
}

interface FormValue {
	state: string;
}

export function ShippingCancel(props: ShippingCancelProps): ReactNode {
	const methods = useForm();
	const { changeShippingState } = useShippingState();

	const handleSubmit = async (values: FormValue) => {
		const shippings = await props.shippings();
		const ids = shippings.map((shipping) => shipping.id);
		const state = values.state;

		await changeShippingState(ids, state);

		props.onDone();
	};
	const handleClickClose = () => {
		props.onClose();
	};

	return (
		<Form className="formbox" methods={methods} onSubmit={handleSubmit}>
			<div className="modalbox flex-box">
				<div className="wrapper-right">
					<h3>ステータスを戻す</h3>
					<article>
						<section>
							<div className="flex-box wrap inputArea">
								<table>
									<tbody>
										<tr>
											<td>ステータス</td>
											<td>
												<Select name="state">
													<option value="waiting">出荷待ち</option>
													<option value="pending">保留</option>
												</Select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
					</article>
					<div className="flex-box submitArea end">
						<Button type="submit">変更</Button>
						<Button onClick={handleClickClose}>閉じる</Button>
					</div>
				</div>
			</div>
		</Form>
	);
}
export default ShippingCancel;
