import * as React from 'react';
import { Link } from 'react-router-dom';
import { ShippingImport } from '../ShippingImport';

interface NaviMenuProps {
	type: string;
}

export const NaviMenu: React.FC<NaviMenuProps> = (props) => {
	const { type } = props;

	return (
		<nav id="sub-nav">
			<ul>
				{type === 'inventory' && <>
					<li className="sku-search">
						<Link to="/inventory/moving">移動伝票</Link>
					</li>
					<li className="sku-search">
						<Link to="/inventory/list">在庫数閲覧</Link>
					</li>
				</>}

				{type === 'shipping' && <>
					<li className="csv-upload">
						<ShippingImport />
					</li>
				</>}
			</ul>
		</nav>
	);
};
export default NaviMenu;