import { getLocationGroups as getLocationGroupsAPI } from "../api";
import { ContextState, useAPI, useAppState } from "../context";
import { LocationGroup } from '../types';

interface UseLocationGroup {
	getLocationGroup: (locationGroupId?: number) => Promise<LocationGroup|undefined>;
	getLocationGroups: () => Promise<LocationGroup[]>;
}

export const useLocationGroup = (): [ContextState["locationGroup"], UseLocationGroup] => {
	const [state] = useAppState();
	const callAPI = useAPI();

	const getLocationGroup = async (locationGroupId?: number): Promise<LocationGroup|undefined> => {
		const result = await callAPI(getLocationGroupsAPI());
		const locationGroups = result.data;
		return locationGroups.find((lg: LocationGroup) => lg.id === locationGroupId);
	};

	const getLocationGroups = async (): Promise<LocationGroup[]> => {
		const result = await callAPI(getLocationGroupsAPI());
		return result.data;
	};

	return [
		state.locationGroup,
		{
			getLocationGroup,
			getLocationGroups,
		}
	];
};