import * as React from 'react';

interface IPPSelectorProps {
	ipp?: number;
	setIPP?: (ipp?: number) => void;
	list?: number[];
}

export const IPPSelector: React.FC<IPPSelectorProps> = (props) => {
	const { ipp, setIPP, list } = props;

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = parseInt(e.currentTarget.value, 10) || undefined;
		setIPP?.(value);
	};

	const ippList = list ?? [100, 200, 500, 1000];
	const value = ippList.includes(ipp ?? 0) ? ipp || ippList[0] : "";
	const options = ippList.map( ipp => <option value={ ipp } key={ ipp }>{ ipp }</option> );

	return (
		<select value={value} onChange={handleChange}>
			{ options }
			<option value="">全て</option>
		</select>
	);
};