import * as React from 'react';

interface PagingProps {
	page: number;
	children: React.ReactNode;
}

interface PaginationProps {
	now: number;
	maxPage: number;
	setPage: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = (props) => {
	const { now, maxPage, setPage } = props;

	const Paging = ({ page, children }: PagingProps) => {
		if (page == now) {
			return <><a><span style={{ fontWeight: "bold" }}>{ children }</span></a> </>;
		}
		if (page < 0 || maxPage < page) {
			return <><a>{ children }</a> </>;
		}
		const onClick = function (e: React.MouseEvent<HTMLAnchorElement>) {
			setPage(page);
			e.stopPropagation();
			e.preventDefault();
		}
		return <><a href="#" onClick={ onClick }>{ children }</a> </>;
	};

	const pages: JSX.Element[] = [];
	for (let i = 0; i <= maxPage; i++) {
		pages.push(<Paging page={ i } key={ i }>{ i + 1 }</Paging>);
	}
	return (
		<div className="pagination">
			<Paging page={ now - 1 }>&lt;</Paging>
			{ pages }
			<Paging page={ now + 1 }>&gt;</Paging>
		</div>
	);
};
